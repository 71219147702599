import React, { useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Input, Button } from 'antd';

import useSystemStore from 'system/store';
import useStore from '../useActionStore';
import CaptureFunctions from '../CaptureFunctions';
import { ROADWAY } from '../CaptureFunctions/constants';
import useMenuStore from '../useMenuStore';

import Placement from './Placement';

const constructStruct = (roadway, intersection, props = {}) => ({
  elementType: 'struct',
  bindType: 'roadway',
  bindId: roadway.id,
  bind: roadway,
  ...intersection,
  ...props,
  id: uuid(),
});

const Confirm = ({ t, onChange }) => {
  return (
    <div className="my-2 mx-8">
      <p>{t('project:Steps.Confirm.Struct')}</p>
      <Input
        placeholder={t('project:Steps.Confirm.name')}
        defaultValue=""
        onChange={onChange}
      />
    </div>
  );
};

export const STEPS = [
  {
    title: 'project:Steps.Struct.Roadway',
    component: CaptureFunctions,
    props: {
      functions: [ROADWAY],
    },
  },
  {
    title: 'project:Steps.Struct.Placement',
    component: Placement,
  },
  {
    title: 'project:Steps.Struct.Confirm',
    component: Confirm,
  },
];

const Struct = ({ step, next, prev }) => {
  const { t } = useTranslation();
  const [roadway, setRoadway] = useState();
  const [intersection, setIntersection] = useState();
  const [name, setName] = useState();

  const { drawing, setDrawing, resetDrawing } = useStore((state) => state);

  const setSelected = useMenuStore((state) => state.setSelected);

  const { system, addDataSet } = useSystemStore((state) => state);

  const handleNext = () => {
    switch (step) {
      case 1: {
        const box = constructStruct(roadway, intersection);
        setDrawing({
          box,
        });
        break;
      }
      case 2: {
        const struct = constructStruct(roadway, intersection, { name });
        addDataSet([struct]);
        resetDrawing();
        setSelected(['draw']);
      }
      default:
        break;
    }
    next();
  };

  const handleChangeName = (event) => setName(event.target.value);

  const captureRoadways = useMemo(() => {
    return system.data.roadways;
  }, [system]);

  const onChange = (value, point) => {
    switch (step) {
      case 0: {
        setRoadway(value);
        setDrawing({ ...drawing, intersection: point });
        setIntersection(point);

        break;
      }
      case 1: {
        setIntersection(value);
        break;
      }
      case 2: {
        setName(value.target.value);
        break;
      }
      default:
        return;
    }
  };

  const { component: Component, props } = STEPS[step];

  const cancelAll = () => {
    resetDrawing();
    setSelected();
  };

  const isFinalStep = step === 2;

  return (
    <div>
      <Component
        {...props}
        t={t}
        onChange={onChange}
        onNameChange={handleChangeName}
        roadway={roadway}
        roadways={captureRoadways}
        current={roadway}
        intersection={intersection}
      />

      <div>
        <Button
          className="mx-1"
          type="primary"
          disabled={!isFinalStep && !roadway}
          onClick={handleNext}
        >
          {isFinalStep
            ? t('translation:general.Submit')
            : t('translation:general.Next')}
        </Button>
        <Button className="mx-1" onClick={cancelAll}>
          {t('translation:general.Cancel')}
        </Button>
      </div>
    </div>
  );
};

export default Struct;
