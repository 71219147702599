import { useEffect } from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import useCaptureStore from 'system/useCaptureStore';

import { getIntersection } from './utils';

const Roadway = ({ roadways, onChange, current }) => {
  const { t } = useTranslation();

  const { setCaptures, resetCaptures, selected, setSelected, point } =
    useCaptureStore((state) => state);

  useEffect(() => {
    setCaptures({ roadways });
    return () => resetCaptures();
  }, [roadways]);

  useEffect(() => {
    if (selected) {
      onChange(selected, getIntersection(selected, point));
    }
    return () => setSelected(null, null);
  }, [selected]);

  return current ? (
    <Alert
      message={`${t('project:CaptureFunctions.Roadway.success')}: ${
        current.name || t('translation:general.Unnamed')
      }`}
      type="success"
      showIcon
    />
  ) : (
    <Alert
      message={t('project:CaptureFunctions.Roadway.info')}
      type="info"
      showIcon
    />
  );
};

export default Roadway;
