import React, { useState, createContext, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';

import useActionStore from 'pages/project/useActionStore';

import { TUNNEL, VENTILATOR, STRUCT } from '../constants';
import Tunnel, { STEPS as TUNNELS } from './Tunnel';
import Ventilator, { STEPS as VENTILATORS } from './Ventilator';
import Struct, { STEPS as STRUCTS } from './Struct';

const StepsContainer = styled.div`
  z-index: 1;
  left: 50%;
  transform: translate(-50%);
`;

const { Step } = Steps;

const STEPS = {
  [TUNNEL]: TUNNELS,
  [VENTILATOR]: VENTILATORS,
  [STRUCT]: STRUCTS,
};

const COMPONENTS = {
  [TUNNEL]: Tunnel,
  [VENTILATOR]: Ventilator,
  [STRUCT]: Struct,
};

export const Context = () => createContext();

export const useStepContext = () => useContext(Context);

const StepsIndicator = ({ hideSteps }) => {
  const { t } = useTranslation('project');
  const [current, setCurrent] = useState(0);
  const drawStatus = useActionStore((state) => state.drawStatus);

  useEffect(() => {
    setCurrent(0);
  }, [drawStatus]);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  if (!drawStatus) {
    return null;
  }

  const steps = STEPS[drawStatus];

  const Component = COMPONENTS[drawStatus];

  return (
    <StepsContainer className="text-center absolute top-0 flex flex-col min-w-min p-2.5 shadow bg-white rounded-b-md">
      {!hideSteps && (
        <>
          <Steps labelPlacement="vertical" size="small" current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={t(item.title)} />
            ))}
          </Steps>
          <hr className="mx-10 my-2" />
        </>
      )}

      <Component step={current} next={next} prev={prev} />
    </StepsContainer>
  );
};

export default StepsIndicator;
