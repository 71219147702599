export const getMapFromArr = (arr) =>
  arr.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});

export const getMapsFromData = (data) => {
  const { points, roadways, ventilators } = data;
  return {
    points: getMapFromArr(points),
    roadways: getMapFromArr(roadways),
    ventilators: getMapFromArr(ventilators),
  };
};

export const getInOutFromPoints = (data) => {
  const { points } = data;
  const results = {
    in: [],
    out: [],
  };
  points.forEach((point) => {
    if (!point.in.length) {
      results.in.push(point);
    }
    if (!point.out.length) {
      results.out.push(point);
    }
  });
  return results;
};

export const getConnectedPointsFromPoint = (point, elements) => {
  const node = elements[point.id];

  if (!node) {
    return [];
  }

  const outs = node.in.map((id) => elements[elements[id].s]);
  const ins = node.out.map((id) => elements[elements[id].t]);

  return [...outs, ...ins];
};

export const getConnectedRoadwaysFromPoint = (point) => {
  const ins = point.in || [];
  const outs = point.out || [];

  console.log('point', point, [...ins, ...outs]);

  return [...ins, ...outs];
};

export const getPointsFromIntersection = (intersection) =>
  intersection.reduce(
    (acc, curr) => [...acc, curr.roadway.s, curr.roadway.t],
    [],
  );

export const makeExceptions = (points, exceptions) => {
  const excepts = new Set(exceptions);
  return points.filter((original) => !excepts.has(original));
};
