import { useEffect, useState, useCallback } from 'react';

import { Button } from 'antd';
import { HighlightOutlined } from '@ant-design/icons';
import useActionStore from 'pages/project/useActionStore';
import useSystemStore from 'system/store';

const PointFocus = ({ data, value }) => {
  const [isHighlighted, setIsHighlighted] = useState(false);
  const { system } = useSystemStore((state) => state);

  const { setDrawing, drawing, resetDrawing } = useActionStore(
    (state) => state,
  );

  const highLight = useCallback(() => {
    const point = system.elements[value];
    setDrawing({ points: [...drawing.points, point] });
  }, [value, setDrawing, system, drawing]);

  const resetHighlight = () => {
    const points = drawing.points.filter((p) => p.id !== value);
    setDrawing({ points });
  };

  const handleClick = () => {
    if (isHighlighted) {
      resetHighlight();
    } else {
      highLight();
    }

    setIsHighlighted((highlight) => !highlight);
  };

  useEffect(() => {
    return () => resetDrawing();
  }, [resetDrawing]);

  return (
    <Button
      onClick={handleClick}
      type={isHighlighted ? 'primary' : 'default'}
      shape="circle"
      icon={<HighlightOutlined />}
    />
  );
};

export default PointFocus;
