import { Button } from 'antd';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import CoordInput from 'components/CoordInput';
import { POINT } from '../constants';

const Coord = ({ onChange, points }) => {
  const [value, setValue] = useState({});

  const id = uuid();

  const handleChange = (val) => {
    setValue(val);
    onChange({ id, elementType: POINT, ...val });
  };

  return (
    <div className="flex flex-row">
      <CoordInput value={value} onChange={handleChange} />
    </div>
  );
};

export default Coord;
