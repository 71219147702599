import { useFrame } from '@react-three/fiber';
import { useRef, useMemo } from 'react';
import * as THREE from 'three';
import { coordToArray } from '../../utils/coord';

const DEFAULT_COLOR = 'rgba(154, 154, 154, 0.7)';

const drawBlades = () => {
  const ctx = document.createElement('canvas').getContext('2d');
  const size = 250;
  ctx.canvas.width = size;
  ctx.canvas.height = size;

  const radius = size / 2 - 10;
  ctx.fillStyle = `rgb(0, 0, 0, 0)`;
  ctx.fillRect(0, 0, size, size);

  // blades
  ctx.strokeStyle = '#aaa';
  ctx.fillStyle = '#ea6632';

  for (let i = 0; i < 6; i++) {
    ctx.save();
    ctx.lineWidth = 10;
    ctx.translate(size / 2, size / 2);
    ctx.rotate(i * (Math.PI / 3));
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(radius, 0);
    ctx.arc(0, 0, radius, 0, Math.PI * (15 / 180));
    ctx.closePath();
    ctx.fill();
    ctx.restore();
  }

  return ctx;
};

const drawFrame = () => {
  const ctx = document.createElement('canvas').getContext('2d');
  const size = 250;
  ctx.canvas.width = size;
  ctx.canvas.height = size;

  const radius = size / 2 - 10;

  ctx.fillStyle = `rgba(255,255,255,0)`;
  ctx.fillRect(0, 0, size, size);

  ctx.strokeStyle = '#e6c836';
  ctx.fillStyle = '#e6c836';

  ctx.beginPath();
  ctx.arc(size / 2, size / 2, radius, 0, 2 * Math.PI);
  ctx.lineWidth = 20;
  ctx.stroke();

  ctx.beginPath();

  ctx.arc(size / 2, size / 2, 60, 0, 2 * Math.PI);
  ctx.fill();

  for (let i = 0; i < 3; i++) {
    ctx.save();
    ctx.lineWidth = 20;
    ctx.translate(size / 2, size / 2);
    ctx.rotate(i * (Math.PI / 3));
    ctx.beginPath();
    ctx.moveTo(-radius, 0);
    ctx.lineTo(radius, 0);
    ctx.stroke();
    ctx.restore();
  }
  return ctx;
};

const SIZE = 10;

const Ventilator = ({

  data,
  color,
  opacity,
  width,
  hide = false,
  animation = false,
}) => {
  const size = width || SIZE;

  const textureRef = useRef(new THREE.CanvasTexture(drawBlades().canvas));
  const texture = textureRef.current;

  const frameTexture = new THREE.CanvasTexture(drawFrame().canvas);

  const geometry = useMemo(() => {
    const geo = new THREE.BoxBufferGeometry(size, size, size);

    const { start, end } = data.bind;
    const s = new THREE.Vector3(...coordToArray(start));
    const e = new THREE.Vector3(...coordToArray(end));

    const direction = new THREE.Vector3().subVectors(e, s);

    geo.lookAt(direction);
    geo.translate(...coordToArray(data));

    geo.groups = [
      {
        start: 0,
        count: Infinity,
        materialIndex: 0,
      },
      {
        start: 0,
        count: 24,
        materialIndex: 1,
      },
      {
        start: 0,
        count: 24,
        materialIndex: 2,
      },
    ];

    return geo;
  }, [data.bind]);

  useFrame(({ clock }) => {
    const a = clock.getElapsedTime();

    if (animation) {
      texture.center.set(0.5, 0.5);
      texture.rotation = -(a * 1.8) % Math.PI;
    }
  });

  return (
    <mesh userData={data} geometry={geometry}>
      {/* <boxBufferGeometry
        args={[size, size, size]}
        groups={[
          {
            start: 0,
            count: Infinity,
            materialIndex: 0,
          },
          {
            start: 0,
            count: 24,
            materialIndex: 1,
          },
          {
            start: 0,
            count: 24,
            materialIndex: 2,
          },
        ]}
      /> */}
      <meshPhongMaterial
        transparent
        visible={!hide}
        attachArray="material"
        color={color || DEFAULT_COLOR}
        opacity={0.7}
        side={THREE.DoubleSide}
      />
      <meshPhongMaterial
        transparent
        visible={!hide}
        attachArray="material"
        map={texture}
        color={'rgba(255,255,255)'}
        opacity={1}
        side={THREE.DoubleSide}
      />

      <meshPhongMaterial
        transparent
        visible={!hide}
        attachArray="material"
        map={frameTexture}
        color={'rgb(255,255,255)'}
        opacity={1 || 0.9}
        side={THREE.DoubleSide}
      />

      {/* {sideFaces.map((b, i) => (
          <MeshPhongMaterial
            key={`${b}-${i}`}
            transparent
            visible={!hide}
            attachArray="material"
            map={texture}
            color={color || DEFAULT_COLOR}
            opacity={opacity || 0.9}
            side={THREE.DoubleSide}
          />
        ))}
        {connectionFaces.map((face) => (
          <meshBasicMaterial
            key={face}
            visible={!hide}
            attachArray="material"
            color={color || DEFAULT_COLOR}
            opacity={opacity || 0.9}
            side={THREE.DoubleSide}
          />
        ))} */}
    </mesh>
  );
};

export default Ventilator;
