import { Tooltip, Radio } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CaptureFunctions = ({ step, functions, onChange, ...props }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(functions[0].value);

  const onTabChange = (event) => {
    setActive(event.target.value);
    onChange(null);
  };

  const activeOption = useMemo(
    () => functions.find(({ value }) => value === active),
    [functions, active],
  );

  const Component = activeOption.component;

  return (
    <>
      {functions.length > 1 && (
        <Radio.Group value={active} onChange={onTabChange}>
          {functions.map((func) => (
            <Tooltip key={func.key} placement="bottom" title={t(func.tooltip)}>
              <Radio.Button value={func.value}>{t(func.title)}</Radio.Button>
            </Tooltip>
          ))}
        </Radio.Group>
      )}
      <div className="mx-10 my-2">
        <Component key={step} {...props} onChange={onChange} />
      </div>
    </>
  );
};

export default CaptureFunctions;
