import { Link } from '@reach/router';
import { Table, Empty, Tag, Space } from 'antd';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { enUS, zhCN } from 'date-fns/locale';

const DATE_LOCALE = {
  en: enUS,
  zh: zhCN,
};

const getColumns = (t, lng) => [
  {
    title: t('projects:table.Name'),
    dataIndex: 'name',
    key: 'name',
    render: (name, record) => <Link to={`/project/${record.id}`}>{name}</Link>,
  },
  {
    title: t('projects:table.Description'),
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: t('projects:table.CreatedAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (time) =>
      formatDistanceToNow(new Date(time), {
        addSuffix: true,
        locale: DATE_LOCALE[lng],
      }),
  },
  {
    title: t('projects:table.UpdatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (time) =>
      formatDistanceToNow(new Date(time), {
        addSuffix: true,
        locale: DATE_LOCALE[lng],
      }),
  },
];

const ProjectList = ({ data = [], loading }) => {
  const { t, i18n } = useTranslation(['projects']);

  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n]);

  const dataSource = useMemo(
    () => data.map((d, i) => ({ ...d, key: i })),
    [data],
  );

  if (!data.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return <Table loading={loading} columns={columns} dataSource={dataSource} />;
};

export default ProjectList;
