import { Input, InputNumber } from 'antd';

const DEFAULT_STEP = 0.001;

const { Group } = Input;

const CoordInput = ({ value, config, onChange }) => {
  const { x, y, z } = value || {};

  const handleChange = (val) => {
    onChange({ x, y, z, ...val });
  };

  const onChangeX = (v) => handleChange({ x: v });
  const onChangeY = (v) => handleChange({ y: v });
  const onChangeZ = (v) => handleChange({ z: v });

  return (
    <Group compact>
      <InputNumber
        step={DEFAULT_STEP}
        placeholder="x"
        value={x}
        onChange={onChangeX}
      />
      <InputNumber
        step={DEFAULT_STEP}
        placeholder="y"
        value={y}
        onChange={onChangeY}
      />
      <InputNumber
        step={DEFAULT_STEP}
        placeholder="z"
        value={z}
        onChange={onChangeZ}
      />
    </Group>
  );
};

CoordInput.defaultProps = {
  config: {
    x: {},
    y: {},
    z: {},
  },
};

export default CoordInput;
