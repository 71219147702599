import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';

import useAxios from 'hooks/useAxios';

import Header from './Header';
import Menus from './Menus';
import PageLoader from 'components/PageLoader';

const { Content, Footer, Sider } = Layout;

const GlobalLayout = ({ children, navigate }) => {
  const { response, loading } = useAxios({ url: '/menu' });

  const [menus, setMenus] = useState([]);

  useEffect(() => {
    if (response) {
      const { home, menus } = response;
      navigate(home);
      setMenus(menus);
    }
  }, [response]);

  if (loading) {
    return <PageLoader />;
  }
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        className="site-layout-sub-header-background"
        style={{ padding: 0, backgroundColor: '#3875AC' }}
      >
        <img className="h-10" alt="jilan-logo" src="/images/jl-logo-text.svg" />
      </Header>
      <Layout>
        <Sider theme="light">
          <div className="logo" />
          <Menus menus={menus} />
        </Sider>
        <Layout>
          <Content>
            {React.cloneElement(children, { style: { height: '100%' } })}
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            Ant Design ©2018 Created by Ant UED
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default GlobalLayout;
