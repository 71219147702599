import { Menu } from 'antd';

import {
  DashboardOutlined,
  DatabaseOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from '@reach/router';

const ICONS = {
  dashboard: DashboardOutlined,
  projects: DatabaseOutlined,
  userManagement: UserOutlined,
};

const Menus = ({ menus }) => {
  const [selectedKeys, setSelectedKeys] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const activeMenu = menus.find(({ path }) => path === location.pathname);

    if (activeMenu) {
      setSelectedKeys([activeMenu.name]);
    }
  }, [menus, setSelectedKeys, location]);

  const handleNavigate = ({ key }) => {
    const { path } = menus.find(({ name }) => name === key);
    navigate(path);
  };

  return (
    <Menu mode="inline" selectedKeys={selectedKeys}>
      {menus.map(({ name, path, icon }, index) => {
        const Icon = ICONS[name];
        return (
          <Menu.Item key={name} icon={<Icon />} onClick={handleNavigate}>
            {name}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default Menus;
