export const NAVIGATION = 'NAVIGATION';
export const CAPTURE = 'CAPTURE';

export const DRAW = 'draw';

export const STATIC = 'static';
export const FLOW = 'flow';

export const ROADWAY = 'roadway';
export const POINT = 'point';
export const TUNNEL = 'tunnel';
export const VENTILATOR = 'ventilator';
export const STRUCT = 'struct';
