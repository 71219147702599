import { useEffect, useState, useMemo } from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import useCaptureStore from 'system/useCaptureStore';

import Roadway from './Roadway';
import Placement from '../Steps/Placement';

const elementType = 'point';

const InsertNode = ({ roadways, onChange, current }) => {
  // const { t } = useTranslation();

  const [point, setPoint] = useState();
  const [roadway, setRoadway] = useState();

  const id = useMemo(() => uuid(), []);

  const handleRoadwayChange = (r, intersection) => {
    setRoadway(r);
    setPoint(intersection);

    // const p = { id, ...intersection };
    onChange({ id, elementType, ...intersection }, { roadway: r, point: id });
  };

  const handlePlacementChange = (p) => {
    setPoint(p);
    onChange({ id, elementType, ...p });
  };

  if (!point) {
    return (
      <Roadway
        roadways={roadways}
        onChange={handleRoadwayChange}
        current={roadway}
      />
    );
  }

  return (
    <Placement
      intersection={point}
      onChange={handlePlacementChange}
      current={roadway}
    />
  );
  return null;
};

export default InsertNode;
