import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

const PageLoader = () => {
  const { t } = useTranslation(['translation']);

  return (
    <div className="w-screen h-screen grid grid-cols-1 place-content-center">
      <Spin size="large" tip={`${t('translation:general.Loading')}`} />
    </div>
  );
};

export default PageLoader;
