import { useMemo } from 'react';
import * as THREE from 'three';
import { coordToArray } from 'utils/coord';

const elementType = 'struct';
const DEFAULT_COLOR = 'rgb(154, 154, 154)';
const SIZE = 4;

const Struct = ({ data, color, width, hide }) => {
  const geometry = useMemo(() => {
    const geo = new THREE.TorusBufferGeometry(width || SIZE, 3, 6, 4);
    const { start, end } = data.bind;
    const s = new THREE.Vector3(...coordToArray(start));
    const e = new THREE.Vector3(...coordToArray(end));

    const direction = new THREE.Vector3().subVectors(e, s);
    geo.rotateZ(THREE.MathUtils.degToRad(-45));

    geo.lookAt(direction);
    geo.translate(...coordToArray(data));
    return geo;
  }, [data]);

  return (
    <mesh userData={{ ...data, elementType }} geometry={geometry}>
      <meshPhongMaterial
        transparent
        visible={!hide}
        attach="material"
        color={color || DEFAULT_COLOR}
        opacity={0.7}
        side={THREE.DoubleSide}
      />
    </mesh>
  );
};

export default Struct;
