import _ from 'lodash';
import { Layout, Dropdown, Menu, Button } from 'antd';
import { EditOutlined, LogoutOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import useAuthContext from 'auth/AuthProvider';

const Container = styled(Layout.Header)`
  background-color: #276dab;
  display: flex;
  align-items: center;
`;

const MenuContainer = styled.div`
  position: relative;
  &:hover {
    cursor: pointer;
    background-color: #0f2841;
  }
`;

let INITIAL_REGEX = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

const getInitials = (name) => {
  let initials = [...name.matchAll(INITIAL_REGEX)] || [];
  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase();

  return initials;
};

const Header = () => {
  const { user, logout } = useAuthContext();

  const name = _.get(user, 'name');

  const handleLogout = (event) => {
    event.preventDefault();
    logout();
  };

  const menu = (
    <Menu>
      <Menu.Item key="edit" icon={<EditOutlined />}>
        <a>Edit Profile</a>
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        <a href="_blank" onClick={handleLogout}>
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Container className="flex justify-between">
      <img className="h-10" alt="jilan-logo" src="/images/jl-logo-text.svg" />
      <MenuContainer className="flex items-center justify-center h-full px-4 hover:bg-gray-400">
        <Dropdown overlay={menu} placement="bottomRight">
          <a
            href="_blank"
            className="ant-dropdown-link h-full flex justify-center items-center"
            onClick={(e) => e.preventDefault()}
          >
            <div className="flex items-center justify-center rounded-full h-10 w-10 bg-white">
              {getInitials(name)}
            </div>
          </a>
        </Dropdown>
      </MenuContainer>
    </Container>
  );
};

export default Header;
