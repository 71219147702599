import React from 'react';
import { Form, Input, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import NumberInput from 'components/NumberInput';
import Select from 'components/Select';
import PointFocus from 'components/PointFocus';

import { normalizeFloat, formatProps } from './utils';

const { Panel } = Collapse;

const StyledPanel = styled(Panel)`
  .ant-collapse-header {
    text-transform: capitalize;
  }
`;

const Item = styled(Form.Item)`
  label {
    text-transform: capitalize;
  }
`;

const SECTIONS = [
  {
    key: 'general',
    header: 'inspector:attrs.general',
    fields: [
      {
        name: 'name',
        label: 'inspector:attrs.name',
      },
      {
        name: 's',
        label: 'inspector:attrs.s',
        component: PointFocus,
      },
      {
        name: 't',
        label: 'inspector:attrs.t',
        component: PointFocus,
      },
    ],
  },
  {
    key: 'geometry',
    header: 'inspector:attrs.geometry',
    fields: [
      {
        name: 'length',
        label: 'inspector:attrs.length',
        component: NumberInput,
        fieldProp: {
          normalize: normalizeFloat,
        },
        inputProp: {
          min: 0,
          unit: <span>m</span>,
        },
      },
      {
        name: 'width',
        label: 'inspector:attrs.width',
        component: NumberInput,
        fieldProp: {
          normalize: normalizeFloat,
        },
        inputProp: {
          min: 0,
          unit: 'm',
        },
      },
      {
        name: 'height',
        label: 'inspector:attrs.height',
        component: NumberInput,
        fieldProp: {
          normalize: normalizeFloat,
        },
        inputProp: {
          min: 0,
          unit: 'm',
        },
      },
      {
        name: 'perimeter',
        label: 'inspector:attrs.perimeter',
        component: NumberInput,
        fieldProp: {
          normalize: normalizeFloat,
        },
        inputProp: {
          min: 0,
          unit: 'm',
        },
      },
      {
        name: 'shape',
        label: 'inspector:attrs.shape',
        component: Select,
        inputProp: {
          placeholder: 'inspector:attrs.shapePlaceholder',
          options: [
            {
              value: 'circular',
              label: 'inspector:attrs.shapes.circular',
            },
            {
              value: 'rectangular',
              label: 'inspector:attrs.shapes.rectangular',
            },
            {
              value: 'semicircle',
              label: 'inspector:attrs.shapes.semicircle',
            },
            {
              value: 'threehearts',
              label: 'inspector:attrs.shapes.threehearts',
            },
            {
              value: 'trapezoidal',
              label: 'inspector:attrs.shapes.trapezoidal',
            },
          ],
        },
      },
      {
        name: 'area',
        label: 'inspector:attrs.area',
        component: NumberInput,
        fieldProp: {
          normalize: normalizeFloat,
        },
        inputProp: {
          min: 0,
          unit: <span>m&sup2;</span>,
        },
      },
    ],
  },
  {
    key: 'ventilation',
    header: 'inspector:attrs.ventilation',
    fields: [
      {
        name: 'r',
        label: 'inspector:attrs.r',
        component: NumberInput,
        fieldProp: {
          normalize: normalizeFloat,
        },
        inputProp: {
          unit: (
            <span>
              N&sdot;s&sup2;/m<sup>8</sup>
            </span>
          ),
        },
      },
      {
        name: 'q',
        label: 'inspector:attrs.q',
        component: NumberInput,
        fieldProp: {
          normalize: normalizeFloat,
        },
        inputProp: {
          disabled: true,
          unit: <span>m&sup3;/s</span>,
        },
      },
    ],
  },
];

const Tunnel = ({ formName, data, onChange, onSubmit }, ref) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onValuesChange = (f, value) => onChange(value);

  return (
    <Form
      ref={ref}
      onFinish={onSubmit}
      name={formName}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      layout="horizontal"
      initialValues={data}
      onValuesChange={onValuesChange}
      size="default"
      form={form}
    >
      <Collapse defaultActiveKey={['general']} bordered>
        {SECTIONS.map(({ key, header, fields }) => (
          <StyledPanel key={key} header={t(header)}>
            {fields.map(
              ({
                name,
                label,
                component: FieldComponent,
                fieldProp,
                inputProp = {},
              }) => (
                <Item
                  key={name}
                  name={name}
                  label={t(label)}
                  colon={false}
                  {...fieldProp}
                >
                  {FieldComponent ? (
                    <FieldComponent
                      name={name}
                      data={data}
                      {...formatProps(inputProp, t)}
                    />
                  ) : (
                    <Input {...inputProp} />
                  )}
                </Item>
              ),
            )}
          </StyledPanel>
        ))}
      </Collapse>
    </Form>
  );
};

export default React.forwardRef(Tunnel);
