import styled from 'styled-components';

import LoginForm from './LoginForm';
import Layout from './Layout';

const LoginFormContainer = styled.div`
  width: 70%;
  margin: 20px auto;
`;

const Login = ({ navigate, location }) => {
  return (
    <Layout>
      <LoginFormContainer className="flex content-center justify-items-center">
        <LoginForm location={location} />
      </LoginFormContainer>
    </Layout>
  );
};

export default Login;
