import { useState } from 'react';
import _ from 'lodash';
import { Modal, Form, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';

import axiosInstance from 'axiosInstance';

const AddProject = ({ isOpen, closeModal }) => {
  const { t } = useTranslation(['projects']);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (value) => {
    setIsLoading(true);
    axiosInstance
      .post('/projects', value)
      .then((response) => {
        const projectId = _.get(response, 'data.projectId');
        if (projectId) {
          closeModal();
          navigate(`/project/${projectId}`);
        } else {
          message.error(
            _.get(
              response,
              'data.error.message',
              t('projects:page.errors.addProject'),
            ),
          );
        }
      })
      .catch((error) => {
        console.log('error', error);
        message.error(t('projects:page.errors.addProject'));
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      destroyOnClose
      title={t('projects:page.addProject')}
      visible={isOpen}
      onCancel={closeModal}
      okButtonProps={{
        htmlType: 'submit',
        form: 'addProject',
        loading: isLoading,
      }}
    >
      <Form
        id="addProject"
        form={form}
        layout="vertical"
        requiredMark="optional"
        preserve={false}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="name"
          label={t('projects:table.Name')}
          required
          rules={[
            {
              required: true,
              message: t('projects:page.errors.name'),
            },
          ]}
        >
          <Input autoFocus />
        </Form.Item>
        <Form.Item name="description" label={t('projects:table.Description')}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddProject;
