import { Slider } from 'antd';
import { useState, useMemo, useEffect } from 'react';
import { getLengthBetweenPoints, getPointAlongLineByLen } from 'utils/coord';
import useActionStore from 'pages/project/useActionStore';
import NumberInput from 'components/NumberInput';

const Placement = ({ current, intersection, onChange }) => {
  const length = useMemo(
    () => getLengthBetweenPoints(current.start, current.end),
    [current],
  );

  const { setDrawing } = useActionStore((state) => state);
  const [distance, setDistance] = useState(
    getLengthBetweenPoints(current.start, intersection),
  );

  useEffect(() => {
    const point = getPointAlongLineByLen(current.start, current.end, distance);
    setDrawing({
      intersection: point,
    });
    onChange(point);
  }, [distance]);

  const handleDistanceChange = (d) => {
    setDistance(d);
  };

  const handleReDistanceChange = (d) => {
    setDistance(length - d);
  };

  return (
    <>
      <div className="flex px-2">
        {/* <div className="ant-input-wrapper ant-input-group w-auto">
          <InputNumber
            min={0}
            max={length}
            value={distance}
            onChange={handleDistanceChange}
          />
          <span className="ant-input-group-addon">m</span>
        </div> */}
        <div className="w-1/3">
          <NumberInput
            value={distance}
            onChange={handleDistanceChange}
            unit="m"
          />
        </div>

        <div className="flex-grow mx-2">
          <Slider
            min={0}
            max={Math.floor(length)}
            onChange={handleDistanceChange}
            value={distance}
          />
        </div>
        <div className="w-1/3">
          {/* <InputNumber
            min={0}
            max={length}
            value={length - distance}
            onChange={handleReDistanceChange}
          /> */}
          <NumberInput
            value={(length - distance).toFixed(4)}
            onChange={handleReDistanceChange}
            unit="m"
          />
        </div>
      </div>
    </>
  );
};

export default Placement;
