import { useMemo } from 'react';
import { ConfigProvider as Provider } from 'antd';
import { useTranslation } from 'react-i18next';

import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';

const CONFIG_LOCALE = {
  en: enUS,
  zh: zhCN,
};
const ConfigProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const locale = useMemo(
    () => CONFIG_LOCALE[i18n.language] || CONFIG_LOCALE.zh,
    [i18n],
  );
  return <Provider locale={locale}>{children}</Provider>;
};

export default ConfigProvider;
