import { useState } from 'react';
import useAxios from 'hooks/useAxios';
import { useTranslation } from 'react-i18next';
import { Button, Radio, Tooltip } from 'antd';
import {
  PlusOutlined,
  TableOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

import ProjectList from './ProjectList';
import AddProject from './AddProject';

const Projects = () => {
  const { t } = useTranslation(['projects']);
  const { response, loading } = useAxios({ url: '/projects' });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="flex flex-col p-6 justify-between">
      <div className="h-12">
        <h3 className="text-2xl text-gray-700">{t('projects:page.title')}</h3>
      </div>
      <div className="flex">
        <Button
          onClick={openModal}
          style={{ display: 'flex', alignItems: 'center' }}
          shape="round"
          icon={<PlusOutlined />}
        >
          {t('projects:page.addProject')}
        </Button>
        <div className="ml-auto flex">
          <Radio.Group defaultValue="list" buttonStyle="solid">
            <Radio.Button value="list">
              <Tooltip placement="bottom" title={t('projects:page.list')}>
                <UnorderedListOutlined style={{ fontSize: '12px' }} />
              </Tooltip>
            </Radio.Button>
            <Radio.Button value="table">
              <Tooltip placement="bottom" title={t('projects:page.table')}>
                <TableOutlined style={{ fontSize: '12px' }} />
              </Tooltip>
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className="min-h-full bg-white my-4">
        <ProjectList data={response || []} loading={loading} />
      </div>
      <AddProject isOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default Projects;
