import { Redirect } from '@reach/router';
import { useState, useEffect } from 'react';
import axiosInstance from 'axiosInstance';
import PageLoader from 'components/PageLoader';

import { AuthProvider } from './AuthProvider';

let accessToken;

export const getAuthToken = () => {
  return `Bearer ${accessToken}`;
};

export const setAuthToken = (token) => {
  accessToken = token;
};

export const cleanUp = () => {
  accessToken = null;
};

const Auth = ({ children, navigate, location }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState();

  useEffect(() => {
    axiosInstance
      .get('/auth/user')
      .then((response) => {
        setUser(response.data);
      })
      .catch((err) => {
        console.log('AUTH err', err);
        navigate('/login', { state: { origin: location } });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  if (accessToken) {
    return (
      <AuthProvider user={user} logout={cleanUp}>
        {children}
      </AuthProvider>
    );
  }

  return <Redirect noThrow={true} to="/login" />;
};

export default Auth;
