// import i18n from 'i18next';
// const t = i18n.t.bind(i18n);

const roundCoord = (number) => (+number).toFixed(3);

export const NODE = [
  {
    name: 'name',
    label: 'project:Info.name',
  },
  {
    name: 'coord',
    label: 'project:Info.coord',
    format: ({ x, y }) => `${roundCoord(x)}, ${roundCoord(y)}`,
  },
  {
    name: 'z',
    label: 'project:Info.altitude',
    format: ({ z }) => roundCoord(z) || 0,
  },
];

export const TUNNEL = [
  {
    name: 'name',
    label: 'project:Info.name',
  },
];

export const VENTILATOR = [
  {
    name: 'name',
    label: 'project:Info.name',
  },
];

export const STRUCT = [
  {
    name: 'name',
    label: 'project:Info.name',
  },
];

export const CONFIGS = {
  point: NODE,
  roadway: TUNNEL,
  ventilator: VENTILATOR,
  struct: STRUCT,
};
