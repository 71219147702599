import React from 'react';
import { Form, Input, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import NumberInput from 'components/NumberInput';
import Select from 'components/Select';
import { normalizeFloat, formatProps } from './utils';

const { Panel } = Collapse;

const StyledPanel = styled(Panel)`
  .ant-collapse-header {
    text-transform: capitalize;
  }
`;

const Item = styled(Form.Item)`
  label {
    text-transform: capitalize;
  }
`;

const SECTIONS = [
  {
    key: 'general',
    header: 'inspector:attrs.general',
    fields: [
      {
        name: 'name',
        label: 'inspector:attrs.name',
      },
      {
        name: 'type',
        label: 'inspector:attrs.struct.type',
        component: Select,
        inputProp: {
          options: [
            {
              value: 'gate',
              label: 'inspector:attrs.struct.types.gate',
            },
            {
              value: 'window',
              label: 'inspector:attrs.struct.types.window',
            },
            {
              value: 'seal',
              label: 'inspector:attrs.struct.types.seal',
            },
          ],
        },
      },
    ],
  },
  {
    key: 'ventilation',
    header: 'inspector:attrs.ventilation',
    fields: [
      {
        name: 'r',
        label: 'inspector:attrs.r',
        component: NumberInput,
        fieldProp: {
          normalize: normalizeFloat,
        },
        inputProp: {
          unit: (
            <span>
              N&sdot;s&sup2;/m<sup>8</sup>
            </span>
          ),
        },
      },
    ],
  },
];

const Struct = ({ formName, data, onChange, onSubmit }, ref) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onValuesChange = (f, value) => onChange(value);

  return (
    <Form
      ref={ref}
      onFinish={onSubmit}
      name={formName}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      layout="horizontal"
      initialValues={data}
      onValuesChange={onValuesChange}
      size="default"
      form={form}
    >
      <Collapse defaultActiveKey={['general']} bordered>
        {SECTIONS.map(({ key, header, fields }) => (
          <StyledPanel key={key} header={t(header)}>
            {fields.map(
              ({
                name,
                label,
                component: FieldComponent,
                fieldProp,
                inputProp = {},
              }) => (
                <Item
                  key={name}
                  name={name}
                  label={t(label)}
                  colon={false}
                  {...fieldProp}
                >
                  {FieldComponent ? (
                    <FieldComponent
                      name={name}
                      {...formatProps(inputProp, t)}
                    />
                  ) : (
                    <Input {...inputProp} />
                  )}
                </Item>
              ),
            )}
          </StyledPanel>
        ))}
      </Collapse>
    </Form>
  );
};

export default React.forwardRef(Struct);
