import create from 'zustand';

import { NAVIGATION, DRAW } from './constants';

const DEFAULT_STRUCTURE = {
  points: [],
  roadways: [],
};

const useActionStore = create((set) => ({
  captured: [],
  drawing: DEFAULT_STRUCTURE,
  status: NAVIGATION,
  setCapture: (captured) => set((state) => ({ captured })),

  drawStatus: null,
  drawData: [],
  setDraw: (drawStatus) => set((state) => ({ status: DRAW, drawStatus })),
  setDrawing: (drawing) =>
    set((state) => ({ drawing: { ...DEFAULT_STRUCTURE, ...drawing } })),
  resetDrawing: () => {
    console.log('reset');
    set((state) => ({
      status: NAVIGATION,
      drawStatus: null,
      drawing: DEFAULT_STRUCTURE,
    }));
  },
  // pushDrawData: (data) => set(state => ({drawData: [...state.drawData, data]})),
  // popDrawData: () => set(state => {
  //   const newArr = [...state.drawData];
  //   newArr.pop();
  //   return ({drawData: newArr});
  // }),
  resetCapture: () => set((state) => ({ captured: [] })),
}));

export default useActionStore;
