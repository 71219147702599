import i18n from 'i18next';
import Backend from 'i18next-http-backend';

import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(Backend)
  .use(LanguageDetector)
  // .use(i18nextPlugin)

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // .use(resourcesToBackend((language, namespace, callback) => {
  //   import(`./locales/${language}/${namespace}.json`)
  //     .then((resources) => {
  //       callback(null, resources)
  //     })
  //     .catch((error) => {
  //       callback(error, null)
  //     })
  // }))
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options

  .init({
    debug: true,
    fallbackLng: 'en',
    defaultNS: 'translation',
    // keySeparator: false,
    react: {
      wait: true,
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        // if (value instanceof Date) return moment(value).format(format);
        return value;
      },
    },
  });
// .init({
//   debug: true,
//   fallbackLng: 'en',
//   interpolation: {
//     escapeValue: false, // not needed for react as it escapes by default
//   },
//   resources: {
//     en: {
//       translation: {
//         general: {
//           property: 'properties',
//           Unnamed: 'Unnamed',
//           Next: 'Next',
//           Submit: 'Submit',
//           Cancel: 'Cancel',
//         },
//         element: {
//           roadway: 'Roadway',
//         },
//         attrs: {
//           general: 'General',
//           name: 'Name',
//           s: 'Start',
//           t: 'To',
//         },
//         menu: {
//           Draw: {
//             index: 'Draw',
//             Tunnel: 'Tunnel',
//           },
//         },
//         project: {
//           Steps: {
//             Tunnel: {
//               Start: 'Start',
//               End: 'End',
//               Confirm: 'Confirm',
//             },
//           },
//           CaptureFunctions: {
//             Node: {
//               info: 'Select a node or reference point on screen',
//               success: 'You have selected',
//             },
//             constants: {
//               title: {
//                 coord: 'Coordination',
//                 node: 'Capture',
//                 auxiliary: 'Auxiliary',
//               },
//               tooltip: {
//                 coord: 'Use the coordination inputted',
//                 node: 'Use the node captured',
//                 auxiliary: 'Use the auxiliary of a line',
//               },
//             },
//           },
//         },
//       },
//     },
//   },
// });

export default i18n;
