import * as THREE from 'three';
import { coordToArray } from 'utils/coord';

export const getIntersection = (roadway, p) => {
  const { start, end } = roadway;
  const a = new THREE.Vector3(...coordToArray(start));
  const b = new THREE.Vector3(...coordToArray(end));

  const D = b.clone().sub(a).normalize();
  const d = p.clone().sub(a).dot(D);

  const point = a.clone().add(D.clone().multiplyScalar(d));

  return point;
};
