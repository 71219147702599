import { Carousel } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 45vw;
`;
const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 450px;
  background: #364d79;
  text-align: center;
`;

const marketMaterials = [
  {
    title: 'An outstanding performance',
    description: '',
  },
  {
    title: 'A sophisticated monitor system',
    description: '',
  },
];

const Marketing = () => {
  return (
    <Container>
      <Carousel effect="fade">
        {marketMaterials.map(({ title, description }, index) => (
          <Content key={index}>
            <h4 className="text-lg font-medium text-white">{title}</h4>
            <div className="text-gray-300">{description}</div>
          </Content>
        ))}
      </Carousel>
    </Container>
  );
};

export default Marketing;
