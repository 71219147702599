import { Sphere } from '@react-three/drei';

import Tunnel from './elements/Tunnel';
import Node from './elements/Node';
import Box from './elements/Box';

import { coordToArray } from '../utils/coord';

const DRAWING_COLOR = 'rgb(59, 130, 246)';

const SIZE = 3.5;

const Drawing = ({ drawing, colorConfig = {} }) => {
  return (
    <>
      {drawing.intersection && (
        <Sphere
          position={coordToArray(drawing.intersection)}
          args={[4, 32, 32]}
        >
          <meshBasicMaterial
            attach="material"
            color={colorConfig[drawing.intersection.id] || DRAWING_COLOR}
          />
        </Sphere>
      )}

      {drawing.box && <Box data={drawing.box} />}

      {drawing.points.map((point) => (
        <Node
          radius={SIZE}
          data={point}
          key={`drawing-${point.id}`}
          color={colorConfig[point.id] || DRAWING_COLOR}
        />
      ))}

      {drawing.roadways.map((roadway) => {
        return (
          <Tunnel
            key={`drawing-${roadway.id}`}
            color={colorConfig[roadway.id] || DRAWING_COLOR}
            opacity={1}
            width={SIZE}
            start={roadway.start}
            end={roadway.end}
            data={roadway}
          />
        );
      })}
    </>
  );
};

export default Drawing;
