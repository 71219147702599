import * as THREE from 'three';
import { useRef, useMemo, useEffect } from 'react';
import _ from 'lodash';

import { useFrame } from '@react-three/fiber';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils';

import { coordToArray } from '../utils/coord';

const DEFAULT_COLOR = 'rgb(124, 124, 124)';

const fragments = 16;
const VERTECIES = fragments * 6;

const getArrow = (opacity = 0.4) => {
  const ctx = document.createElement('canvas').getContext('2d');
  ctx.canvas.width = 128;
  ctx.canvas.height = 128;

  ctx.fillStyle = `rgba(255, 255, 255, 0)`;
  ctx.fillRect(0, 0, 128, 128);

  ctx.translate(64, 64);
  ctx.fillStyle = 'rgba(24, 48, 242, 0.95)';
  ctx.beginPath();
  ctx.moveTo(0, -20);
  ctx.lineTo(-12, -4);
  ctx.lineTo(-6, -4);

  ctx.lineTo(-6, 20);
  ctx.lineTo(6, 20);
  ctx.lineTo(6, -4);
  ctx.lineTo(12, -4);
  ctx.closePath();
  ctx.fill();

  return ctx;
};

const arrow = getArrow();

const getColors = (colors) => [
  DEFAULT_COLOR,
  ...new Set(Object.values(colors)),
];

const Roadways = ({
  roadways = [],
  width = 3,
  flowAnimation = true,
  capture = false,
  colors = {},
}) => {
  const colorsConfig = useMemo(() => {
    let index = 0;
    return getColors(colors).reduce(
      (acc, curr) => ({ ...acc, [curr]: index++ }),
      {},
    );
  }, [colors]);

  const arrowMaterials = useMemo(
    () =>
      roadways.map((roadway) => {
        const { start, end } = roadway;
        const s = new THREE.Vector3(...coordToArray(start));
        const e = new THREE.Vector3(...coordToArray(end));
        const direction = new THREE.Vector3().subVectors(e, s);

        const arrowTexture = new THREE.CanvasTexture(arrow.canvas);
        arrowTexture.wrapS = THREE.RepeatWrapping;
        arrowTexture.wrapT = THREE.RepeatWrapping;
        arrowTexture.repeat.x = 4;
        arrowTexture.repeat.y = parseInt(direction.length() / 20);

        return new THREE.MeshPhongMaterial({
          transparent: true,
          opacity: 0.9,
          map: arrowTexture,
          side: THREE.FrontSide,
          depthWrite: false,
        });
      }),
    [roadways],
  );

  const colorMaterials = useMemo(() => {
    const materials = getColors(colors).map(
      (color) =>
        new THREE.MeshPhongMaterial({
          visible: true,
          color,
          transparent: true,
          opacity: 0.6,
          side: THREE.DoubleSide,
        }),
    );
    return materials;
  }, [colors]);

  const extraMaterial = useRef(arrowMaterials);

  useEffect(() => {
    extraMaterial.current = arrowMaterials;
  }, [arrowMaterials]);

  useFrame(({ clock }) => {
    const a = clock.getElapsedTime();
    flowAnimation &&
      extraMaterial.current.forEach(
        (material) => (material.map.offset.y = -(a * 0.25) % 1),
      );
  });

  const geometries = useMemo(() => {
    if (_.isEmpty(roadways)) {
      return null;
    }

    const geoms = roadways.map((roadway, index) => {
      const { start, end } = roadway;
      const s = new THREE.Vector3(...coordToArray(start));
      const e = new THREE.Vector3(...coordToArray(end));
      const direction = new THREE.Vector3().subVectors(e, s);
      const center = new THREE.Vector3().addVectors(s, e).multiplyScalar(0.5);

      const geometry = new THREE.CylinderBufferGeometry(
        width,
        width,
        direction.length(),
        fragments,
        1,
        true,
        THREE.MathUtils.degToRad(45),
      );

      geometry.applyMatrix4(
        new THREE.Matrix4().makeRotationFromEuler(
          new THREE.Euler(Math.PI / 2, Math.PI, 0),
        ),
      );
      geometry.lookAt(direction);
      geometry.translate(...coordToArray(center));

      geometry.userData = { elementType: 'roadway', ...roadway };
      return geometry;
    });

    const merged = BufferGeometryUtils.mergeBufferGeometries(geoms);

    if (capture) {
      return merged;
    }

    merged.groups = [
      ...roadways.map((roadway, index) => ({
        start: index * VERTECIES,
        count: VERTECIES,
        materialIndex: colors[roadway.id]
          ? colorsConfig[colors[roadway.id]]
          : 0,
      })),
      ...roadways.map((roadway, index) => ({
        start: index * VERTECIES,
        count: VERTECIES,
        materialIndex: index + colorMaterials.length,
      })),
    ];

    return merged;
  }, [roadways, width]);

  if (_.isEmpty(roadways)) {
    return null;
  }

  if (capture) {
    return (
      <mesh
        geometry={geometries}
        userData={{ data: roadways, elementType: 'roadways', faces: fragments * 2 }}
      >
        <meshPhongMaterial
          visible={false}
          color={DEFAULT_COLOR}
          transparent
          opacity={0.6}
          side={THREE.DoubleSide}
        />
      </mesh>
    );
  }

  return (
    <mesh
      geometry={geometries}
      material={[...colorMaterials, ...(capture ? [] : extraMaterial.current)]}
    />
  );
};

export default Roadways;
