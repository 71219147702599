import { useMemo } from 'react';
import * as THREE from 'three';
import _ from 'lodash';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { coordToArray } from '../utils/coord';

const FRAGMENTS = 16;
const FACES = 30 * FRAGMENTS;
const DEFAULT_COLOR = 'rgb(124, 124, 124)';

const Nodes = ({ points, radius, capture }) => {
  const geometries = useMemo(() => {
    if (_.isEmpty(points)) {
      return null;
    }

    const geoms = points.map((point) => {
      const r = radius || 3;
      const position = coordToArray(point);

      const geometry = new THREE.SphereBufferGeometry(r, FRAGMENTS, FRAGMENTS);
      geometry.translate(...position);

      geometry.userData = { elementType: 'point', ...point };
      return geometry;
    });
    return BufferGeometryUtils.mergeBufferGeometries(geoms);
  }, [points]);

  if (_.isEmpty(points)) {
    return null;
  }

  return (
    <mesh
      geometry={geometries}
      userData={{ elementType: 'points', data: points, faces: FACES }}
    >
      <meshPhongMaterial
        visible={!capture}
        transparent
        opacity={0.9}
        color={DEFAULT_COLOR}
        side={THREE.DoubleSide}
      />
    </mesh>
  );
};

export default Nodes;
