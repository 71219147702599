import { Select as AntdSelect } from 'antd';

const { Option } = AntdSelect;

const Select = (props) => (
  <AntdSelect {...props}>
    {props.options.map(({ value, label }) => (
      <Option key={value} value={value}>
        <span className="capitalize">{label || value}</span>
      </Option>
    ))}
  </AntdSelect>
);

export default Select;
