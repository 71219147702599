import Node from './Node';
import Coord from './Coord';
import Roadway from './Roadway';
import InsertNode from './InsertNode';

export const NODE = {
  key: 'node',
  value: 'node',
  tooltip: 'project:CaptureFunctions.constants.tooltip.node',
  title: 'project:CaptureFunctions.constants.title.node',
  component: Node,
};

export const COORD = {
  key: 'coord',
  value: 'coord',
  tooltip: 'project:CaptureFunctions.constants.tooltip.coord',
  title: 'project:CaptureFunctions.constants.title.coord',
  component: Coord,
};

export const ROADWAY = {
  key: 'roadway',
  value: 'roadway',
  tooltip: 'project:CaptureFunctions.constants.tooltip.roadway',
  title: 'project:CaptureFunctions.constants.title.roadway',
  component: Roadway,
};

export const AUXILIARY = {
  key: 'auxiliary',
  value: 'auxiliary',
  tooltip: 'project:CaptureFunctions.constants.tooltip.auxiliary',
  title: 'project:CaptureFunctions.constants.title.auxiliary',
  component: Coord,
};

export const INSERTNODE = {
  key: 'insert',
  value: 'insert',
  tooltip: 'project:CaptureFunctions.constants.tooltip.insertNode',
  title: 'project:CaptureFunctions.constants.title.insertNode',
  component: InsertNode,
};

// const SupportOptions = ['工字钢', ....]

// data = {
//   ...data,
//   support: event.target.value
// }

// config [
//   1. Basic/ 基础
//   [
//     {
//       name: 's',
//       component: 'NodeInput' -> <template></template>
//     }, {
//       name: 'support',
//       component: 'Select' -> <select value onChange v-model={data[name]}
//       options: SupportOptions
//     }
//   ]
// ]
