import * as THREE from 'three';
import { coordToArray } from 'utils/coord';

const DEFAULT_COLOR = 'rgb(124, 124, 124)';

const elementType = 'point';

const Node = ({
  id,
  point,
  data,
  color,
  radius = 3,
  opacity,
  hide = false,
}) => {
  // const captured = useCaptureStore((state) => state.captured);

  // const captureColor = color ||
  //   (captured && captured.userData.id === id ? CAPTURE_COLOR : DEFAULT_COLOR);

  return (
    <mesh
      position={coordToArray(data)}
      userData={{ ...data, type: elementType, elementType }}
    >
      <sphereBufferGeometry args={[radius || 3, 16, 16]} />
      <meshPhongMaterial
        visible={!hide}
        transparent
        opacity={opacity || 0.9}
        color={color || DEFAULT_COLOR}
        side={THREE.DoubleSide}
      />
    </mesh>
  );
};

export default Node;
