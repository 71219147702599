import { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';
import { coordToArray } from 'utils/coord';

const DEFAULT_COLOR = 'rgb(124, 124, 124)';

const Box = ({ size = 10, depth, data, color, hide }) => {
  const line = useRef();

  const args = useMemo(() => [size, size, depth || size], [size, depth]);

  const box = useMemo(() => {
    const { start, end } = data.bind;
    const geo = new THREE.BoxGeometry(...args);

    const s = new THREE.Vector3(...coordToArray(start));
    const e = new THREE.Vector3(...coordToArray(end));

    const direction = new THREE.Vector3().subVectors(e, s);

    geo.lookAt(direction);
    geo.translate(...coordToArray(data));
    return geo;
  }, [data.bind, args]);

  // const wireframe = useMemo(() => new THREE.EdgesGeometry(box), [box]);

  useEffect(() => {
    line.current && line.current.computeLineDistances();
  }, [hide]);

  return (
    <>
      <mesh userData={data} geometry={box}>
        <meshPhongMaterial
          visible={!hide}
          transparent
          attach="material"
          color={color || DEFAULT_COLOR}
          opacity={0.5}
          side={THREE.DoubleSide}
        />
      </mesh>
      {/* {!hide && (
        <lineSegments ref={line} geometry={wireframe} userData={data}>
          <lineDashedMaterial
            transparent
            color={DEFAULT_COLOR}
            opacity={0.9}
            linewidth={2}
            gapSize={0.5}
            dashSize={2}
          />
        </lineSegments>
      )} */}
    </>
  );
};

export default Box;
