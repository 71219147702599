import styled from 'styled-components';
import { Layout as ALayout } from 'antd';
import { useMediaQuery } from 'beautiful-react-hooks';

import Marketing from './Marketing';

const { Content, Footer } = ALayout;

const Container = styled(ALayout)`
  color: #fff8e3;
  overflow-x: hidden;
`;
const Header = styled.div``;

const ContentContainer = styled.div`
  flex: 1;
  max-width: 420px;
  margin: 0 auto;
  min-width: 25%;
`;

const MarketingContainer = styled.div`
  flex: 1;
`;

const Layout = ({ children }) => {
  const isScreen = useMediaQuery('(min-width: 768px)');

  return (
    <Container className="flex flex-col justify-between h-screen w-screen">
      <Header
        style={{ padding: '10px' }}
        className="flex items-center justify-center"
      >
        {/* <Logo alt="logo" src="/images/jl-logo.png" />
        <div className="text-white px-5 text-2xl font-medium">基蓝软件</div> */}
      </Header>
      <Content style={{ minHeight: '500px', padding: '20px' }} className="flex">
        <ContentContainer>{children}</ContentContainer>
        {isScreen && (
          <MarketingContainer>
            <Marketing />
          </MarketingContainer>
        )}
      </Content>
      <Footer className="shadow-md">Footer Content</Footer>
    </Container>
  );
};

export default Layout;
