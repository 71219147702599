import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import useCaptureStore from '../useCaptureStore';
import { CONFIGS } from './constants';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  z-index: 1;
  position: fixed;
  top: 20px;
  left: 20px;
  transform: translate(${({ x, y }) => `${x}px, ${y}px`});
`;

const getValue = (config, data) => {
  if (config.format) {
    return config.format(data);
  }
  return data[config.name];
};

const Infos = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="p-1 text-xs">
      {_.get(CONFIGS, data.elementType, []).map((config) => (
        <dl key={config.name} className="grid grid-cols-3 gap-x-2 gap-y-1">
          <dt className="capitalize mr-1">{t(config.label)}: </dt>
          <dd className="col-span-2">{getValue(config, data)}</dd>
        </dl>
      ))}
    </div>
  );
};

const InfoPanel = ({ mousePosition }) => {
  const captured = useCaptureStore((state) => state.captured);

  if (!captured) {
    return null;
  }

  return (
    <Container
      className="absolute bg-gray-50 border-gray-400 rounded-sm shadow-sm text-gray-800"
      {...mousePosition}
    >
      <Infos data={captured} />
    </Container>
  );
};

export default InfoPanel;
