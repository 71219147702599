import { useState, useRef } from 'react';
import { Drawer, Button, message, Popconfirm, Modal } from 'antd';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import useSystemStore from 'system/store';
import { NAVIGATION } from 'system/constants';

import Tunnel from './Tunnel';
import Ventilator from './Ventilator';
import Struct from './Struct';
import { getDeleteData } from './utils';

const PANELS = {
  roadway: {
    title: 'inspector:element.roadway',
    component: Tunnel,
  },
  point: {
    title: 'inspector:element.point',
    component: Tunnel,
  },
  ventilator: {
    title: 'inspector:element.ventilator',
    component: Ventilator,
  },
  struct: {
    title: 'inspector:element.struct',
    component: Struct,
  },
};

const Inspector = ({ selected, status, onClose }) => {
  const { t } = useTranslation(['translation', 'inspector']);
  const form = useRef();
  const [pristine, setPristine] = useState(true);

  const { metas, system, removeDataSet, updateMeta } = useSystemStore(
    (state) => state,
  );

  const elementType = _.get(selected, 'elementType', '');
  const { title, component: Component } = PANELS[elementType] || {};

  const handleClose = () => {
    onClose();
    setPristine(true);
  };

  const handleForceClose = () => {
    if (!pristine) {
      Modal.confirm({
        title: t('inspector:change.title'),
        icon: <ExclamationCircleOutlined />,
        content: t('inspector:change.content'),
        okText: t('inspector:change.confirm'),
        cancelText: t('inspector:change.cancel'),
        onOk() {
          form.current.submit();
        },
        onCancel() {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };

  const handleSubmit = (form) => {
    const values = _.pickBy(form, (v) => v !== undefined);
    updateMeta(elementType, { ...metas[selected.id], ...values });
    handleClose();
  };

  const handleDelete = () => {
    removeDataSet(getDeleteData(selected));

    message.error(
      `${t('inspector:general.deleteSuccess')} ${t(
        `inspector:element.${selected.elementType}`,
      )}`,
    );
    handleClose();
  };

  const handleDeleteCancel = () => {};

  return (
    <Drawer
      className="text-gray-600"
      key={_.get(selected, 'id')}
      title={`${t(title)} ${t('inspector:general.property')}`}
      width={350}
      onClose={handleForceClose}
      mask={false}
      visible={!!selected}
      bodyStyle={{ padding: 0, paddingBottom: 40, overflowY: 'auto' }}
      footer={
        <div className="flex justify-between">
          <Popconfirm
            title={t('inspector:general.delete')}
            onConfirm={handleDelete}
            onCancel={handleDeleteCancel}
            okText={t('translation:general.Ok')}
            cancelText={t('translation:general.Cancel')}
          >
            <Button danger>{t('translation:general.Delete')}</Button>
          </Popconfirm>

          <Button className="mr-2 ml-auto" onClick={handleClose}>
            {t('translation:general.Cancel')}
          </Button>
          <Button
            disabled={pristine}
            form={`inspector-${elementType}-${_.get(selected, 'id')}`}
            key="submit"
            htmlType="submit"
            type="primary"
          >
            {t('translation:general.Submit')}
          </Button>
        </div>
      }
    >
      {status === NAVIGATION && selected && (
        <Component
          ref={form}
          onChange={() => setPristine(!form.current.isFieldsTouched())}
          onSubmit={handleSubmit}
          formName={`inspector-${elementType}-${_.get(selected, 'id')}`}
          data={selected}
        />
      )}
    </Drawer>
  );
};

export default Inspector;
