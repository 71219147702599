import { useEffect, useRef, useState } from 'react';
import { Spin, Result, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import getHash from 'object-hash';
import DiffMatchPatch from 'diff-match-patch';
// import stringify from 'json-stable-stringify';

import Layout from './Layout';
import Canvas from 'system/Canvas';
import useSystemStore from 'system/store';
import useAxios from 'hooks/useAxios';
import usePrevious from 'hooks/usePrevious';
import axios from 'axiosInstance';

const diff = new DiffMatchPatch();

const Project = ({ projectId }) => {
  const hash = useRef();
  const { response, loading, error } = useAxios({
    url: `/project/${projectId}`,
  });
  const { system, setMeta } = useSystemStore((state) => state);
  const [info, setInfo] = useState({});

  const { meta } = system;

  const oldMeta = usePrevious(meta);

  const { t } = useTranslation(['project']);

  useEffect(() => {
    if (response) {
      const { name, description } = response;
      setMeta(response.data);
      setInfo({ name, description });

      console.log('hash', getHash(response.data) === response.hash);
      hash.current = getHash(response.data);
    }
  }, [response, setMeta]);

  useEffect(() => {
    // meta update
    const newHash = getHash(meta);
    if (hash.current && newHash !== hash.current) {
      // calc diff
      const diffText = diff.patch_toText(
        diff.patch_make(JSON.stringify(oldMeta), JSON.stringify(meta)),
      );
      const finalHash = getHash(JSON.parse(JSON.stringify(meta)));
      console.log('newHash', newHash === finalHash);

      if (newHash !== finalHash) {
        console.log('meta diff', meta, JSON.parse(JSON.stringify(meta)));
      }
      // send diff
      axios
        .put(`/project/${projectId}`, {
          hash: newHash,
          originalHash: hash.current,
          diff: diffText,
        })
        .then((res) => {
          hash.current = newHash;
        });
    }
  }, [system.meta]);

  if (error && error.status === 403) {
    return (
      <Result
        status="403"
        title="403"
        subTitle={t('project:General.Unauthorized')}
        extra={
          <Button type="primary" onClick={() => navigate('/')}>
            {t('project:General.Back')}
          </Button>
        }
      />
    );
  }

  if (loading) {
    return (
      <div className="w-screen h-screen flex flex-1 items-center justify-center">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }

  return (
    <Layout info={info}>
      <Canvas system={system} />
    </Layout>
  );
};

export default Project;
