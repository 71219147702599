import * as THREE from 'three';

export const coordToArray = (coord = {}) => [parseFloat(coord.x), parseFloat(coord.y), parseFloat(coord.z) || 0];

export const getPointAlongLineByLen = (a, b, length) => {
  const { start, end } = formatPointToVector3(a, b);
  const dir = end.clone().sub(start).normalize().multiplyScalar(length);
  return start.clone().add(dir);
};

const formatPointToVector3 = (a, b) => ({
  start: new THREE.Vector3(...coordToArray(a)),
  end: new THREE.Vector3(...coordToArray(b)),
});

export const getPointAlongLineByPercentage = (a, b, percentage) => {
  const { start, end } = formatPointToVector3(a, b);

  let dir = end.clone().sub(start);
  const len = dir.length();
  dir = dir.normalize().multiplyScalar(len * percentage);
  return start.clone().add(dir);
};

export const getLengthBetweenPoints = (a, b, fixed = 3) => {
  const { start, end } = formatPointToVector3(a, b);
  const dir = end.clone().sub(start);
  const len = dir.length();

  return len.toFixed(fixed);
};
