import * as THREE from 'three';
import { useRef, useEffect, useMemo } from 'react';
import { getBoundingBox } from './camera';

const Grids = ({ points }) => {
  const grid = useRef();

  const bounding = useMemo(() => getBoundingBox(points), [points]);

  useEffect(() => {
    grid.current.geometry.rotateX(Math.PI / 2);
  }, []);

  useEffect(() => {
    const { minX, minY, minZ, maxX, maxY, maxZ } = bounding;
    const min = new THREE.Vector3(minX, minY, minZ);
    const max = new THREE.Vector3(maxX, maxY, maxZ);
    const boundingBox = new THREE.Box3(min, max);

    let center = new THREE.Vector3();
    boundingBox.getCenter(center);

    grid.current.position.set(center.x, center.y, Math.floor(minZ - 200));
  }, [bounding]);

  return <gridHelper ref={grid} args={[1000, 50, 0x444444, 0x888888]} />;
};

export default Grids;
