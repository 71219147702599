import { Suspense } from 'react';
import { Spin } from 'antd';

import './App.css';
import 'antd/dist/antd.less';
import './theme.less';

import ConfigProvider from './ConfigProvider';
import Router from './router';

function App() {
  return (
    <Suspense
      fallback={
        <div className="w-screen h-screen grid grid-cols-1 place-content-center">
          <Spin size="large" />
        </div>
      }
    >
      <ConfigProvider>
        <Router />
      </ConfigProvider>
    </Suspense>
  );
}

export default App;
