import _ from 'lodash';

import useCaptureStore from './useCaptureStore';
import Node from './elements/Node';
import Tunnel from './elements/Tunnel';

import Roadways from './Roadways';
import Nodes from './Nodes';
import Box from './elements/Box';

const CAPTURE_COLOR = 'rgb(236, 255, 0)';
const SELECT_COLOR = 'rgb(52, 211, 153)';
const DEFAULT_COLOR = 'rgb(255,255,255)';

const getColor = (isSelected, isCaptured) => {
  if (isSelected) {
    return SELECT_COLOR;
  }
  if (isCaptured) {
    return CAPTURE_COLOR;
  }
  return DEFAULT_COLOR;
};

const renderElement = (data, color) => {
  if (!data) return null;

  switch (data.elementType) {
    case 'roadway':
      return (
        <Tunnel
          key={`${data.id}-${color}`}
          {...data}
          data={data}
          width={3.8}
          color={color}
        />
      );
    case 'point':
      return (
        <Node
          key={`${data.id}-${color}`}
          data={data}
          radius={3.8}
          color={color}
        />
      );
    default:
      return null;
  }
};

const getIntersect = (event) => {
  const { intersections } = event;
  if (!intersections.length) return null;
  const intersect = intersections[0];
  const { faces } = intersect.object.userData;

  if (faces) {
    const index = Math.floor(intersect.faceIndex / faces);
    const userData = _.get(
      intersect,
      `object.geometry.userData.mergedUserData[${index}]`,
      {},
    );

    return [userData, intersect.point];
  } else {
    return [intersections[0].object.userData, intersect.point];
  }
};

const Captures = ({ pointsMap }) => {
  const { captures, selected, captured, setCapture, setSelected } =
    useCaptureStore((state) => state);

  const onPointerOver = (event) => {
    setCapture(...getIntersect(event));
  };

  const onPointerOut = (event) => {
    setCapture(null);
  };

  const onClick = (event) => {
    setSelected(...getIntersect(event));
  };

  const { points, roadways, lines, ventilators, structs } = captures;

  return (
    <>
      <group
        onPointerEnter={onPointerOver}
        onPointerOut={onPointerOut}
        onClick={onClick}
      >
        <Roadways
          roadways={roadways}
          flowAnimation={false}
          capture
          width={3.8}
        />

        <Nodes points={points} capture radius={5} />

        {(ventilators || []).map((ventilator) => {
          const isCaptured = captured && ventilator.id === captured.id;
          const isSelected = selected && ventilator.id === selected.id;

          isCaptured && console.log('vent', captured.id);

          return (
            <Box
              key={`capture-${ventilator.id}`}
              data={{ elementType: 'ventilator', ...ventilator }}
              size={12}
              hide={!isCaptured && !isSelected}
              color={getColor(isSelected, isCaptured)}
            />
          );
        })}

        {(structs || []).map((struct) => {
          const isCaptured = captured && struct.id === captured.id;
          const isSelected = selected && struct.id === selected.id;

          return (
            <Box
              key={`capture-${struct.id}`}
              data={{ elementType: 'struct', ...struct }}
              size={10}
              depth={6}
              hide={!isCaptured && !isSelected}
              color={getColor(isSelected, isCaptured)}
            />
          );
        })}

        {/* {(lines || []).map((line) => {
        return (
          <Line
            key={line.id}
            {...line}
            s={coordToArray(pointsMap[line.s])}
            e={coordToArray(pointsMap[line.t])}
          />
        );
      })} */}
        {captured && renderElement(captured, CAPTURE_COLOR)}
        {selected && renderElement(selected, SELECT_COLOR)}
      </group>
    </>
  );
};

export default Captures;
