import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import _ from 'lodash';
import * as THREE from 'three';
import CC from 'camera-controls';
import {
  GizmoHelper,
  GizmoViewport,
  OrbitControls,
  TrackballControls,
} from '@react-three/drei';

import InfoPanel from './info/InfoPanel';
import Captures from './Captures';

import {
  Canvas as FiberCanvas,
  useFrame,
  useThree,
  extend,
} from '@react-three/fiber';

import usePrevious from 'hooks/usePrevious';

import useActionStore from 'pages/project/useActionStore';

import { NAVIGATION } from './constants';
import useCaptureStore from './useCaptureStore';
import Ventilator from './elements/Ventilator';

import { fitCameraToObject, getBoundingBox } from './camera';

import Nodes from './Nodes';
import Roadways from './Roadways';

import Struct from './elements/Struct';
import Drawing from './Drawing';
import Grids from './Grids';

CC.install({ THREE: THREE });
extend({ OrbitControls, TrackballControls });

const RENDER_DEBOUNCE_TIME = 350;

const CameraControls = ({ onChange, points }) => {
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls component.
  // https://threejs.org/docs/#examples/en/controls/OrbitControls
  const {
    scene,
    camera,
    gl: { domElement },
  } = useThree();
  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef();
  const prevPoints = usePrevious(points);
  // const cameraControls = new CC(camera, domElement);

  const controlChange = () => {
    onChange();
  };

  useEffect(() => {
    window.scene = scene;
    window.THREE = THREE;
  }, []);

  useEffect(() => {
    const bounding = getBoundingBox(points);

    console.log('bounding', bounding);
    fitCameraToObject(camera, bounding, controls, 2, !prevPoints && points);

    controls.current.addEventListener('change', controlChange);
    const controlsRef = controls.current;

    return () => {
      controlsRef && controlsRef.removeEventListener('change', controlChange);
    };
  }, [points]);

  useFrame((state) => {
    controls.current.update();
  });

  return (
    <>
      <GizmoHelper
        alignment="bottom-right"
        margin={[80, 80]}
        onTarget={() => controls.current.target}
        onUpdate={() => controls.current.update()}
      >
        <GizmoViewport
          axisColors={['#E02626', '#77E035', '#1BE0C8']}
          labelColor="#7A7A7A"
        />
      </GizmoHelper>
      {/* <PerspectiveCamera name="FBO Camera" /> */}
      {/* <perspectiveCamera */}
      {/* <trackballControls ref={controls} args={[camera, domElement]} /> */}
      <OrbitControls
        enableDamping={false}
        ref={controls}
        camera={camera}
        domElement={domElement}
      />
    </>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

const Canvas = ({ system }) => {
  const [mousePosition, setMousePosition] = useState({});
  const [navigating, setNavigating] = useState(false);

  // const { system, setMeta } = useSystemStore((state) => state);
  const { elements, data, meta } = system;

  const { drawing, status } = useActionStore((state) => state);
  const { setCaptures } = useCaptureStore((state) => state);

  // const prevData = usePrevious(original);

  // const diffText = useRef();

  // const { data: sampleJson } = meta || {};

  // useEffect(() => {
  //   if (sampleJson) {
  //     setMeta(sampleJson);
  //     console.log('hash', hash(sampleJson) === meta.hash, hash(sampleJson));
  //   }
  // }, [sampleJson, setMeta]);

  // useEffect(() => {
  //   console.log('navigating change:', navigating);
  // }, [navigating]);

  // const { scene } = useThree();
  // useEffect(() => {
  //   window.scene = scene;
  //   window.THREE = THREE;
  // }, []);

  useEffect(() => {
    if (data && status === NAVIGATION) {
      setCaptures({
        structs: data.structs,
        roadways: data.roadways,
        ventilators: data.ventilators,
      });
      // Diff calculation
      // if (prevData) {
      //   diffText.current = diff.patch_toText(
      //     diff.patch_make(JSON.stringify(data), JSON.stringify(prevData)),
      //   );
      // }
    }
  }, [setCaptures, status, data]);

  const onMouseMove = _.throttle(
    (event) => setMousePosition({ x: event.clientX, y: event.clientY }),
    100,
  );

  const recoverNavigation = () => setNavigating(false);

  const debouncedStateRecover = useMemo(
    () => _.debounce(recoverNavigation, RENDER_DEBOUNCE_TIME),
    [],
  );

  const changeNavigation = useCallback(() => {
    setNavigating(true);
    debouncedStateRecover();
  }, []);

  // const reverseData = () => {
  //   const [text] = diff.patch_apply(
  //     diff.patch_fromText(diffText.current),
  //     JSON.stringify(data),
  //   );
  //   console.log('text', text);
  //   setData(JSON.parse(text));
  // };

  return (
    <ErrorBoundary>
      <div className="relative w-full h-full" onMouseMove={onMouseMove}>
        <InfoPanel mousePosition={mousePosition} />
        <FiberCanvas id="main-canvas">
          <CameraControls points={data.points} onChange={changeNavigation} />
          <ambientLight />

          <Grids points={data.points} />

          <Nodes points={data.points} />
          <Roadways roadways={data.roadways} />

          {data.ventilators.map((element) => (
            <Ventilator key={element.id} data={element} />
          ))}

          {data.structs.map((element) => (
            <Struct key={element.id} data={element} />
          ))}

          <Drawing drawing={drawing} />
          <Captures />
        </FiberCanvas>
      </div>
    </ErrorBoundary>
  );
};

export default Canvas;
