import _ from 'lodash';
import pluralize from 'pluralize';
import create from 'zustand';
import convertToSystem from './convertToSystem';
import { addMetaData, removeFromMeta } from './utils';

const store = create((set) => ({
  // State
  system: {
    meta: {},
    elements: {},
    data: {},
  },
  // Actions
  setMeta: (meta) => set((state) => ({ ...convertToSystem(meta) })),

  updateMeta: (type, data) =>
    set((state) => {
      const { meta } = state.system;

      const types = pluralize(type);

      const newMeta = meta[types].map((original) =>
        original.id === data.id ? data : original,
      );

      console.log('meta', meta[types], data);

      return { ...convertToSystem({ ...meta, [types]: newMeta }) };
    }),

  addDataSet: (dataSets, removeIds) =>
    set((state) => {
      let meta = state.system.meta;
      if (removeIds) {
        meta = removeFromMeta(removeIds, state.system.meta);
      }

      return {
        ...convertToSystem(addMetaData(dataSets, meta)),
      };
    }),
  removeDataSet: (ids) =>
    set((state) => ({
      ...convertToSystem(removeFromMeta(ids, state.system.meta)),
    })),
}));

export default store;
