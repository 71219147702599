import { useEffect } from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import useCaptureStore from 'system/useCaptureStore';

const Node = ({ points, onChange, current }) => {
  const { t } = useTranslation();

  const { setCaptures, resetCaptures, selected, setSelected } = useCaptureStore(
    (state) => state,
  );

  useEffect(() => {
    setCaptures({ points });
    console.log('cap change', points);

    return () => resetCaptures();
  }, [points]);

  useEffect(() => {
    if (selected) {
      console.log('Node change');
      onChange(selected);
    }
    return () => setSelected(null);
  }, [selected]);

  return !!current ? (
    <Alert
      message={`${t('project:CaptureFunctions.Node.success')}: ${
        current.name || t('translation:general.Unnamed')
      }`}
      type="success"
      showIcon
    />
  ) : (
    <Alert
      message={t('project:CaptureFunctions.Node.info')}
      type="info"
      showIcon
    />
  );
};

export default Node;
