import axios from 'axios';
import { navigate } from '@reach/router';
import { getAuthToken, setAuthToken, cleanUp } from './auth';
import { BASE_URL } from './appConstants';

let isRefreshing = false;
const refreshSubscribers = [];

const API_REFRESH_TOKEN = '/auth/refresh-token';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache', // IE 11 specific
    Expires: -1, // IE 11 specific
    // https://support.microsoft.com/en-au/help/234067/how-to-prevent-caching-in-internet-explorer
  },
  withCredentials: true,
});

const status = [401, 403];
const notAuthenticated = (response) =>
  response ? status.includes(response.status) : false;
const subscribeToRefresh = (cb) => {
  refreshSubscribers.push(cb);
};
const onRefreshed = () => {
  refreshSubscribers.map((cb) => cb());
  refreshSubscribers.length = 0; // Empty subscribers array after all callbacks are executed
};

const redirectToLogin = () => {
  cleanUp();
  //   window.location.href = '/login';
  navigate('/login', { state: { origin: window.location.pathname } });
};

const createAxiosInstance = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const originalConfig = config;
      const authToken = getAuthToken();
      if (authToken) {
        originalConfig.headers.Authorization = authToken;
      }
      return Promise.resolve(originalConfig);
    },
    (error) => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const { config, response } = error;

      if (notAuthenticated(response)) {
        /*
          Attempt to refresh accessToken if the request is not a retry
          Second unsuccessful retry will redirect to login
        */
        if (!config._retry) {
          config._retry = true;

          if (!isRefreshing) {
            isRefreshing = true;

            refreshAccessToken()
              .then((accessToken) => {
                setAuthToken(accessToken);
                isRefreshing = false;
                onRefreshed();
              })
              .catch((err) => {
                isRefreshing = false;
                redirectToLogin();
                // console.log('redirect', err);
                // return Promise.reject(response);
              });
          }

          const retryOrig = new Promise((resolve) => {
            subscribeToRefresh(() => {
              config.headers.Authorization = getAuthToken();
              config.baseURL = undefined; // Otherwise results in urls like /api/v1/api/v1
              resolve(axiosInstance(config));
            });
          });

          return retryOrig;
        }
        // Do not need to redirect to logout as session is effectively does not exist at this stage
        // redirectToLogin();

        // This is here jic if workflow changes
        // Currently will not get executed as redirectToLogin();
        return Promise.reject(response);
      }

      /*
      Resolving instead of rejecting to keep handling errors inside try blocks
      TODO: move handling of all errors >= 400 into catch block
      then change this to Promise.reject(response);
       */
      return Promise.resolve(response);
    },
  );

  return axiosInstance;
};

export const refreshAccessToken = () =>
  axios({
    baseURL: BASE_URL,
    method: 'post',
    url: API_REFRESH_TOKEN,
    withCredentials: true,
  }).then((response) => {
    const accessToken = response.data;
    //   auth.setAuthToken(accessToken);
    //   auth.setRefreshToken(refreshToken);
    return accessToken;
  });

createAxiosInstance();

export default axiosInstance;
