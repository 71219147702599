import { InputNumber } from 'antd';
import './numberInput.less';
// const { Option } = Select;

const NumberInput = (props) => {
  return (
    <div className="ant-input-wrapper ant-input-group addonAfterInput">
      <InputNumber {...props} style={{ width: '100%' }} />
      {!!props.unit && (
        <div className="ant-input-group-addon">{props.unit}</div>
      )}
    </div>
  );
};

export default NumberInput;
