import styled from 'styled-components';

const Container = styled.div`
  background-color: #3875ac;
`;

const Header = ({ name, description }) => (
  <Container className="rounded p-2 m-4">
    <h4 className="text-lg text-gray-100">{name}</h4>
    <div className="text-xs text-gray-300">{description}</div>
  </Container>
);

export default Header;
