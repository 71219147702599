import React, { useMemo, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import * as THREE from 'three';

import { ROADWAY } from 'pages/project/constants';
import useActionStore from 'pages/project/useActionStore';
import { Button } from 'antd';
import useMenuStore from '../useMenuStore';

import useSystemStore from 'system/store';
import {
  getConnectedPointsFromPoint,
  getConnectedRoadwaysFromPoint,
  getPointsFromIntersection,
  makeExceptions,
} from 'system/utils';
import { getSystemElements } from 'system/store/utils';
import { coordToArray } from 'utils/coord';

import { getDeleteData } from '../Inspector/utils';
import CaptureFunctions from '../CaptureFunctions';
import {
  NODE,
  COORD,
  AUXILIARY,
  INSERTNODE,
} from '../CaptureFunctions/constants';

const getLength = (points) => {
  const start = new THREE.Vector3(...coordToArray(points[0]));
  const end = new THREE.Vector3(...coordToArray(points[1]));

  const direction = new THREE.Vector3().subVectors(end, start);
  return parseFloat(direction.length().toFixed(4));
};

const getInsertedRoadways = (intersection, elements, points) =>
  intersection.reduce((acc, curr) => {
    const { point, roadway } = curr;

    const { name } = roadway;

    const pointsMap = points.reduce(
      (acc, curr) => ({ ...acc, [curr.id]: curr }),
      {},
    );

    const roadway1 = {
      elementType: ROADWAY,
      id: uuid(),
      name: name ? `${roadway.name}-1` : '',
      s: roadway.s,
      t: point,
      length: getLength([elements[roadway.s], pointsMap[point]]),
    };

    const roadway2 = {
      elementType: ROADWAY,
      id: uuid(),
      name: name ? `${roadway.name}-2` : '',
      s: point,
      t: roadway.t,
      length: getLength([elements[roadway.t], pointsMap[point]]),
    };

    return [...acc, roadway1, roadway2];
  }, []);

const ConfirmTunnel = ({ t, name, onNameChange }) => {
  return (
    <div className="my-2 mx-8">
      <p>{t('project:Steps.Confirm.Tunnel')}</p>
      <Input
        placeholder={t('project:Steps.Confirm.name')}
        value={name}
        onChange={onNameChange}
      />
    </div>
  );
};

export const STEPS = [
  {
    title: 'project:Steps.Tunnel.Start',
    component: CaptureFunctions,
    props: {
      functions: [NODE, COORD, INSERTNODE],
    },
  },
  {
    title: 'project:Steps.Tunnel.End',
    component: CaptureFunctions,
    props: {
      functions: [NODE, COORD, INSERTNODE],
    },
  },
  {
    title: 'project:Steps.Tunnel.Confirm',
    component: ConfirmTunnel,
  },
];

const Tunnel = ({ children, step, next, prev }) => {
  const { t } = useTranslation();
  const [points, setPoints] = useState([]);
  const [name, setName] = useState();
  const [intersection, setIntersection] = useState([]);

  const [current, setCurrent] = useState(null);

  const { drawing, setDrawing, resetDrawing } = useActionStore(
    (state) => state,
  );

  const setSelected = useMenuStore((state) => state.setSelected);

  const { system, addDataSet } = useSystemStore((state) => state);
  const { data, elements, ids } = system;

  const roadways = useMemo(() => {
    if (step === 0) {
      return data.roadways;
    }
    const rs = makeExceptions(ids.roadways, [
      ...intersection.map((i) => i.roadway.id),
      ...getConnectedRoadwaysFromPoint(points[0]),
    ]);

    console.log('rs', rs, ids.roadways);

    return getSystemElements(rs, elements);
  }, [data.roadways, intersection, step]);

  const capturePoints = useMemo(() => {
    if (step === 0) {
      return data.points;
    }
    if (step === 1) {
      const ps = makeExceptions(ids.points, [
        ...points.map((p) => p.id),
        ...getConnectedPointsFromPoint(points[0], elements).map(({ id }) => id),
        ...getPointsFromIntersection(intersection),
      ]);

      console.log('pos', ps);

      return getSystemElements(ps, elements);
    }
    return [];
  }, [step, elements, ids, points, data, intersection]);

  useEffect(() => {
    setCurrent(null);
  }, [step]);

  const handleNext = () => {
    switch (step) {
      case 0:
        setPoints(() => [current]);
        break;
      case 1: {
        setPoints((p) => [...p, current]);
        const start = points[0];
        const end = current;
        const newTunnel = { id: uuid(), s: start.id, t: end.id, start, end };
        setDrawing({ ...drawing, roadways: [newTunnel] });
        break;
      }
      case 2: {
        const removals = intersection.reduce(
          (acc, curr) => [...acc, curr.roadway.id, ...curr.roadway.binds],
          [],
        );

        addDataSet(
          [
            ...points,
            {
              elementType: 'roadway',
              id: uuid(),
              s: points[0].id,
              t: points[1].id,
              name,
              length: getLength(points),
            },
            ...getInsertedRoadways(intersection, elements, points),
          ],
          removals,
        );
        resetDrawing();
        setSelected(['draw']);
        break;
      }
      default:
        return;
    }
    next();
  };

  const handleChangeName = (event) => setName(event.target.value);

  const onChange = (value, inserts) => {
    if (!value) {
      setCurrent(null);
      return setDrawing({ ...drawing, points });
    }
    setCurrent({ ...value });
    setDrawing({ ...drawing, points: [...points, value] });

    if (inserts) {
      // console.log('inserts', inserts);
      setIntersection((i) => [...i, inserts]);
    }
  };

  const { component: Component, props } = STEPS[step];

  const cancelAll = () => {
    resetDrawing();
    setSelected();
  };

  const isFinalStep = step === 2;

  return (
    <div>
      <Component
        {...props}
        step={step}
        t={t}
        onChange={onChange}
        onNameChange={handleChangeName}
        points={capturePoints}
        roadways={roadways}
        current={current}
      />

      <div>
        <Button
          className="mx-1"
          type="primary"
          disabled={!isFinalStep && !current}
          onClick={handleNext}
        >
          {isFinalStep
            ? t('translation:general.Submit')
            : t('translation:general.Next')}
        </Button>
        <Button className="mx-1" onClick={cancelAll}>
          {t('translation:general.Cancel')}
        </Button>
      </div>
    </div>
  );
};

export default Tunnel;
