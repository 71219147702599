import pluralize from 'pluralize';
import _ from 'lodash';

export const getSystemElements = (ids = [], elements) =>
  ids.map((id) => elements[id]);

export const addMetaData = (arr, meta) => {
  const dataSets = arr.reduce((acc, curr) => {
    const { elementType, ...props } = curr;

    const type = pluralize(elementType);

    const sets = acc[type] || [];
    return { ...acc, [type]: [...sets, { ...props }] };
  }, {});

  const result = Object.keys(meta).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: _.concat(meta[curr], ...(dataSets[curr] || [])),
    }),
    {},
  );
  return result;
};

export const removeFromMeta = (ids, meta) => {
  const idMaps = new Set(ids);

  return Object.keys(meta).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: meta[curr].filter(({ id }) => !idMaps.has(id)),
    }),
    {},
  );
};
