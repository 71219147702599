import { navigate } from '@reach/router';
import axiosInstance from 'axiosInstance';
import { useContext, createContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children, user, logout }) => {
  const handleLogout = async () => {
    await axiosInstance.post('/auth/logout').then(() => {
      logout();
      navigate('/');
    });
  };

  return (
    <AuthContext.Provider value={{ user, logout: handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => useContext(AuthContext);

export default useAuthContext;
