import create from 'zustand';

import { BASIC_STRUCTURES, NAVIGATION } from './constants';

const useCaptureStore = create((set) => ({
  captured: null,
  selected: null,
  point: null,
  captures: BASIC_STRUCTURES,
  // highlights: BASIC_STRUCTURES,
  status: NAVIGATION,
  setCaptures: (captures) => set((state) => ({ captures })),
  setCapture: (captured) => set((state) => ({ captured })),
  setSelected: (selected, point) => set((state) => ({ selected, point })),
  // setHighlights: (highlights) => set((state) => ({ highlights })),
  resetCaptures: () => set((state) => ({ captures: BASIC_STRUCTURES })),
  resetCapture: () => set((state) => ({ captured: null })),
  // resetHighlights: () => set((state) => ({ highlights: BASIC_STRUCTURES })),
}));

export default useCaptureStore;
