import pluralize from 'pluralize';
import {
  POINT,
  ROADWAY,
  VENTILATOR,
  STRUCT,
} from '../../pages/project/constants';
import { getSystemElements } from './utils';

const updateBind = (obj, map) => {
  const { bindId } = obj;
  const bind = map[bindId]; // bind Obj
  bind.binds.push(obj.id);
  return { bind };
};

const parseCoord = (coord) => {
  try {
    return parseFloat(coord);
  } catch (error) {
    return 0;
  }
};

const ARRAY_ELEMENTS = [
  {
    elementType: POINT,
    key: pluralize(POINT),
    initializer: (point) => ({
      x: parseCoord(point.x),
      y: parseCoord(point.y),
      z: parseCoord(point.z),
      in: [],
      out: [],
    }),
  },
  {
    elementType: ROADWAY,
    key: pluralize(ROADWAY),
    initializer: (roadway, map) => {
      const start = map[roadway.s];
      const end = map[roadway.t];
      start.out.push(roadway.id);
      end.in.push(roadway.id);
      return {
        start,
        end,
        binds: [],
      };
    },
  },
  {
    elementType: VENTILATOR,
    key: pluralize(VENTILATOR),
    initializer: updateBind,
  },
  { elementType: STRUCT, key: pluralize(STRUCT), initializer: updateBind },
];

const convertMetaToSystem = (arr = [], map, { elementType, initializer }) =>
  arr.reduce((acc, element) => {
    const extras = initializer ? initializer(element, map) : {};
    return {
      ...acc,
      [element.id]: {
        ...element,
        elementType,
        ...extras,
      },
    };
  }, {});

export const convertToSystemMap = (data) => {
  const converted = ARRAY_ELEMENTS.reduce((acc, curr) => {
    const { key } = curr;
    const maps = convertMetaToSystem(data[key], acc, curr);

    return { ...acc, ...maps };
  }, {});

  return converted;
};

const convertMetaToMap = (data) =>
  ARRAY_ELEMENTS.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.key]: data[curr.key].map(({ id }) => id),
    }),
    {},
  );

const getSystemData = (ids, elements) =>
  Object.keys(ids).reduce(
    (acc, key) => ({
      ...acc,
      [key]: getSystemElements(ids[key], elements),
    }),
    {},
  );

const convertToSystem = (meta) => {
  const elements = convertToSystemMap(meta);
  const ids = convertMetaToMap(meta);

  return {
    system: {
      meta,
      elements,
      ids,
      data: getSystemData(ids, elements),
    },
    metas: covertMetaToMaps(meta),
  };
};

const arrToMap = (arr) =>
  arr.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});

export const covertMetaToMaps = (meta) =>
  Object.values(meta).reduce(
    (acc, curr) => ({ ...acc, ...arrToMap(curr) }),
    {},
  );

export default convertToSystem;
