import styled from 'styled-components';

const Logo = styled.img`
  height: 25px;
`;

const Header = styled.div`
  background-color: #276dab;
`;

const FormHeader = () => (
  <Header className="flex items-center px-10 py-4">
    <Logo alt="logo" src="/images/jl-logo.png" />
    <div className="text-white px-5 text-xl font-medium">基蓝软件</div>
  </Header>
);

export default FormHeader;
