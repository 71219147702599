import { useState } from 'react';
import { Layout, Menu } from 'antd';
import styled from 'styled-components';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import useCaptureStore from 'system/useCaptureStore';

import { TUNNEL, VENTILATOR, STRUCT } from '../constants';
import useActionStore from '../useActionStore';
import Steps from '../Steps';
import Inspector from '../Inspector';
import useMenuStore from '../useMenuStore';

import Header from './Header';

const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const StyledLayout = styled(Layout)`
  height: 100vh;
  overflow: hidden;
`;

const MainCanvas = styled(Layout)`
  position: relative;
`;

const MenuList = styled(Menu)`
  height: 100%;
`;

const AppLayout = ({ children, info }) => {
  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = (toggle) => setCollapsed(toggle);
  const { t } = useTranslation(['menu']);

  const { status, setDraw } = useActionStore((state) => state);
  const { selected, setSelected } = useCaptureStore((state) => state);

  const { selectedKeys, setSelected: setSelectedMenu } = useMenuStore(
    (state) => state,
  );

  const onSelect = ({ item, key, keyPath }) => {
    if (selectedKeys && selectedKeys.length && selectedKeys[0] === key) {
      setSelectedMenu(selectedKeys.slice(1));
    } else {
      setSelected(null);
      setDraw(key);
      setSelectedMenu(keyPath);
    }
  };

  return (
    <StyledLayout className="bg-gray-50">
      <Sider
        theme="light"
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <Header {...info} />
        <MenuList
          onSelect={onSelect}
          theme="light"
          selectedKeys={selectedKeys}
          mode="inline"
        >
          <Menu.Item key="1">Option 1</Menu.Item>
          <Menu.Item key="2">Option 2</Menu.Item>
          <SubMenu key="draw" title={t('Draw.index')} icon={<EditOutlined />}>
            <Menu.Item key={TUNNEL}>{t('Draw.Roadway')}</Menu.Item>
            <Menu.Item key={VENTILATOR}>{t('Draw.Ventilator')}</Menu.Item>
            <Menu.Item key={STRUCT}>{t('Draw.Struct')}</Menu.Item>
          </SubMenu>
          <SubMenu key="sub2" title="Team">
            <Menu.Item key="6">Team 1</Menu.Item>
            <Menu.Item key="8">Team 2</Menu.Item>
          </SubMenu>
          <Menu.Item key="9">Files</Menu.Item>
        </MenuList>
      </Sider>
      <MainCanvas>
        {children}
        <Inspector
          status={status}
          selected={selected}
          onClose={() => setSelected(null)}
        />
        <Steps />
      </MainCanvas>
    </StyledLayout>
  );
};

export default AppLayout;
