import * as THREE from 'three';
import _ from 'lodash';
export const getBoundingBox = (points = []) => {
  if (points.length < 2) {
    return {
      minX: -1000,
      maxX: 1000,
      minY: -1000,
      maxY: 1000,
      minZ: 0,
      maxZ: 300,
    };
  }

  const defaultPoint = _.get(points, '[0]', { x: 0, y: 0, z: 0 });

  return points.reduce(
    (acc, curr) => {
      const { x, y, z } = curr;
      const { minX, minY, minZ, maxX, maxY, maxZ } = acc;
      return {
        minX: Math.min(x, minX),
        minY: Math.min(y, minY),
        minZ: Math.min(z, minZ),
        maxX: Math.max(x, maxX),
        maxY: Math.max(y, maxY),
        maxZ: Math.max(z, maxZ),
      };
    },
    {
      minX: defaultPoint.x,
      minY: defaultPoint.y,
      minZ: defaultPoint.z,
      maxX: defaultPoint.x,
      maxY: defaultPoint.y,
      maxZ: defaultPoint.z,
    },
  );
};
// https://discourse.threejs.org/t/camera-zoom-to-fit-object/936
export const fitCameraToObject = (
  camera,
  bound,
  controls,
  offset = 1.5,
  initialCamera = false,
) => {
  const { minX, minY, minZ, maxX, maxY, maxZ } = bound;

  const min = new THREE.Vector3(minX, minY, minZ);
  const max = new THREE.Vector3(maxX, maxY, maxZ);
  const boundingBox = new THREE.Box3(min, max);

  let center = new THREE.Vector3();
  boundingBox.getCenter(center);

  let size = new THREE.Vector3();
  boundingBox.getSize(size);

  const maxDim = Math.max(size.x, size.y, size.z);

  const fov = camera.fov * (Math.PI / 180);

  let cameraZ = Math.abs((maxDim / 2) * Math.tan(fov * 2));

  cameraZ *= offset;

  const cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;
  camera.far = cameraToFarEdge * 3;
  camera.updateProjectionMatrix();

  if (controls.current) {
    if (initialCamera) {
      controls.current.target.copy(center);
      camera.position.set(center.x, center.y, cameraZ + maxZ);
      controls.current.update();
    }
    controls.current.maxDistance = cameraToFarEdge * 2.5; // original 2
  } else {
    camera.lookAt(center);
  }
};
