import _ from 'lodash';
import { ROADWAY, STRUCT, VENTILATOR } from '../constants';

export const normalizeFloat = (value) => parseFloat(value || '0');

export const formatProps = (props, t) => ({
  ...props,
  placeholder: formatPlaceholder(props.placeholder, t),
  options: formatOptions(props.options, t),
});

const formatPlaceholder = (placeholder, t) => t(placeholder, placeholder);

export const formatOptions = (options, t) =>
  (options || []).map((option) => {
    if (typeof option === 'object') {
      return {
        ...option,
        label: t(option.label || option.value, option.value),
      };
    }

    return {
      value: option,
      label: t(option, option),
    };
  });

const isFinalTunnel = (point, id) =>
  [...point.in, ...point.out].filter((e) => e !== id).length === 0;

export const getDeleteData = (data) => {
  let removals = [];
  switch (data.elementType) {
    case ROADWAY:
      removals = [...data.binds, data.id];

      // Check empty node
      const { start, end, id } = data;

      if (isFinalTunnel(start, id)) {
        removals.push(start.id);
      }
      if (isFinalTunnel(end, id)) {
        removals.push(end.id);
      }
      break;
    case VENTILATOR:
    case STRUCT:
      removals = [data.id];
      break;
    default:
      return removals;
  }

  console.log('removal ids', removals);
  return removals;
};
