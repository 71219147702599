import { Router as RootRouter } from '@reach/router';
import styled from 'styled-components';
import Auth from './auth';
import Projects from './pages/projects';
import Project from './pages/project';
import Login from './auth/Login';
import GlobalLayout from './layouts/GlobalLayout';

const StyledRouter = styled(RootRouter)`
  flex: 1;
`;

const Router = () => {
  return (
    <RootRouter>
      <Login path="/login" />
      <Auth path="/">
        <GlobalLayout path="/">
          <Projects path="projects" />
        </GlobalLayout>
        <Project path="project/:projectId" />
      </Auth>
    </RootRouter>
  );
};

export default Router;
