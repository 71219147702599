import { useCallback, useEffect, useRef, useMemo } from 'react';
import * as THREE from 'three';

import { useFrame } from '@react-three/fiber';
import useCaptureStore from '../useCaptureStore';
import { coordToArray } from '../../utils/coord';

const getArrow = (opacity = 0.4) => {
  const ctx = document.createElement('canvas').getContext('2d');
  ctx.canvas.width = 128;
  ctx.canvas.height = 128;

  ctx.fillStyle = `rgba(255, 255, 255, 0)`;
  ctx.fillRect(0, 0, 128, 128);

  ctx.translate(64, 64);
  ctx.rotate(Math.PI * 0.5);
  ctx.fillStyle = 'rgba(24, 48, 242, 0.95)';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.font = '48px sans-serif';
  ctx.fillText('⬅', 0, 0);
  return ctx;
};

const arrow = getArrow();

const DEFAULT_COLOR = 'rgb(124, 124, 124)';

const elementType = 'roadway';
const fragments = 8;

const Tunnel = ({
  data,
  start: s,
  end: e,
  color,
  opacity,
  flowAnimation = true,
  hide = false,
  width = 3,
}) => {
  const mesh = useRef();
  const geometry = useRef();

  const arrowTexture = useMemo(() => new THREE.CanvasTexture(arrow.canvas), []);
  arrowTexture.wrapS = THREE.RepeatWrapping;
  arrowTexture.wrapT = THREE.RepeatWrapping;
  arrowTexture.repeat.x = 4;

  const textureRef = useRef(arrowTexture);

  const texture = textureRef.current;

  useFrame(({ clock }) => {
    const a = clock.getElapsedTime();
    if (flowAnimation) {
      texture.offset.y = -(a * (data.speed || 1)) % 1;
    }
  });

  const start = new THREE.Vector3(...coordToArray(s));
  const end = new THREE.Vector3(...coordToArray(e));

  const direction = new THREE.Vector3().subVectors(end, start);
  const center = new THREE.Vector3().addVectors(start, end).multiplyScalar(0.5);
  const length = direction.length();

  useEffect(() => {
    geometry.current.applyMatrix4(
      new THREE.Matrix4().makeRotationFromEuler(
        new THREE.Euler(Math.PI / 2, Math.PI, 0),
      ),
    );
    geometry.current.lookAt(direction);
    geometry.current.translate(...coordToArray(center));

    texture.repeat.y = parseInt(length / 20);
  }, []);

  // const onGeometryUpdate = useCallback(
  //   (self) => {
  //     console.log('geo');
  //     self.applyMatrix(new THREE.Matrix4().makeTranslation(0, length / 2, 0));
  //     // // rotate it the right way for lookAt to work
  //     self.applyMatrix(
  //       new THREE.Matrix4().makeRotationX(THREE.Math.degToRad(90)),
  //     );
  //   },
  //   [length],
  // );

  // const onMeshUpdate = useCallback((self) => {
  //   self.lookAt(end);
  // }, []);

  // const onMeshUpdate = useCallback((self) => {
  //   // console.log('update');
  //   // self.position.copy(start);
  //   // self.position.lerp(end, 0.5);
  //   // self.lookAt(end);
  //   texture.wrapS = THREE.RepeatWrapping;
  //   texture.wrapT = THREE.RepeatWrapping;
  //   texture.repeat.x = 4;
  //   texture.repeat.y = parseInt(length / 20);
  // }, []);

  return (
    <mesh ref={mesh} userData={{ ...data, elementType }}>
      <cylinderBufferGeometry
        ref={geometry}
        args={[
          width,
          width,
          length,
          fragments,
          1,
          true,
          THREE.Math.degToRad(45),
        ]}
      />
      <meshPhongMaterial
        visible={!hide}
        color={color || DEFAULT_COLOR}
        transparent
        opacity={opacity || 0.6}
        side={THREE.DoubleSide}
      />
    </mesh>
  );
};

export default Tunnel;
