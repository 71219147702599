import React, { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import axios from 'axios';
import { Button, Form, Input, Space, Checkbox, message } from 'antd';
import { Link, navigate } from '@reach/router';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { setAuthToken } from './index';
import FormHeader from './FormHeader';
import { BASE_URL } from 'appConstants';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 20000,
  withCredentials: true,
});

const DEFAULT_ROOT = '/projects';

const LoginForm = ({ location }) => {
  const { t } = useTranslation(['auth']);

  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (value) => {
    setIsLoading(true);
    await instance
      .post('/auth/login', value)
      .then((res) => {
        setAuthToken(res.data);
        // console.log('state.origin', state.origin);
        navigate(_.get(location, 'state.origin', DEFAULT_ROOT));
      })
      .catch((err) => {
        console.log(err);
        message.error(t('auth:Login.errorMessage'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="w-full rounded-lg shadow-lg bg-white overflow-hidden">
      <FormHeader />
      <div className="p-10">
        <Form
          style={{ margin: '0 auto' }}
          name="login"
          onFinish={handleLogin}
          initialValues={{ remember: true }}
        >
          <Space
            style={{
              width: '100%',
              maxWidth: '250px',
            }}
            direction="vertical"
          >
            <Form.Item
              name="account"
              rules={[
                {
                  required: true,
                  message: t('auth:Login.accountMessage'),
                },
              ]}
            >
              <Input
                size="large"
                placeholder={t('auth:Login.accountPlaceholder')}
                prefix={<UserOutlined />}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t('auth:Login.passwordMessage'),
                },
              ]}
            >
              <Input.Password
                size="large"
                placeholder="密码"
                prefix={<LockOutlined />}
              />
            </Form.Item>

            <Row>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>记住我</Checkbox>
              </Form.Item>

              <Link to="/forgot-password">忘记密码</Link>
            </Row>

            <Button
              loading={isLoading}
              block
              type="primary"
              htmlType="submit"
              size="large"
            >
              登录
            </Button>

            <Link to="/register">没有账号？现在注册</Link>
          </Space>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;
