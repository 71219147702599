export const NAVIGATION = 'NAVIGATION';
export const CAPTURE = 'CAPTURE';

export const STATIC = 'static';
export const FLOW = 'flow';

export const BASIC_STRUCTURES = {
  roadways: [],
  points: [],
  ventilators: [],
  structs: [],
};
